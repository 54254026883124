﻿
@mixin chamfer-top-left($chamfer-width, $chamfer-height) {
    clip-path: polygon(
		0% #{$chamfer-height},
        #{$chamfer-width} 0%,
        100% 0%,
        100% 100%,
        0% 100%
	);
}
@mixin chamfer-top-right($chamfer-width, $chamfer-height) {
    clip-path: polygon(
		0% 0%,
        calc(100% - #{$chamfer-width}) 0%,
        100% #{$chamfer-height},
        100% 100%,
        0% 100%
	);
}
@mixin chamfer-bottom-right($chamfer-width, $chamfer-height) {
    clip-path: polygon(
		0% 0%,
        100% 0%,
        100% calc(100% - #{$chamfer-height}),
        calc(100% - #{$chamfer-width}) 100%,
        0% 100%
	);
}
@mixin chamfer-bottom-left($chamfer-width, $chamfer-height) {
    clip-path: polygon(
		0% 0%,
        100% 0%,
        100% 100%,
        #{$chamfer-width} 100%,
        0% calc(100% - #{$chamfer-height})
	);
}
@mixin chamfer($chamfer-width, $chamfer-height) {
    &.top-left {
        @include chamfer-top-left($chamfer-width, $chamfer-height);
    }
    &.top-right {
        @include chamfer-top-right($chamfer-width, $chamfer-height);
    }
    &.bottom-right {
        @include chamfer-bottom-right($chamfer-width, $chamfer-height);
    }
    &.bottom-left {
        @include chamfer-bottom-left($chamfer-width, $chamfer-height);
    }
}
$chamfer-sizes: (
    tiny: (chamfer-width: 45px, chamfer-height: 30px),
    small: (chamfer-width: 90px, chamfer-height: 60px),
    medium: (chamfer-width: 165px, chamfer-height: 110px),
    medium-large: (chamfer-width: 240px, chamfer-height: 160px),
    large: (chamfer-width: 390px, chamfer-height: 260px),
    extra-large: (chamfer-width: 480px, chamfer-height: 320px),
);

﻿
@import "../Mixins/_chamfer.scss";

.chamfer {
    @each $name, $size in $chamfer-sizes {
        $chamfer-width: map-get($size, chamfer-width);
        $chamfer-height: map-get($size, chamfer-height);

        &.#{$name} {
            &.top-left {
                @include chamfer-top-left($chamfer-width: $chamfer-width, $chamfer-height: $chamfer-height)
            }
            &.top-right {
                @include chamfer-top-right($chamfer-width: $chamfer-width, $chamfer-height: $chamfer-height)
            }
            &.bottom-right {
                @include chamfer-bottom-right($chamfer-width: $chamfer-width, $chamfer-height: $chamfer-height)
            }
            &.bottom-left {
                @include chamfer-bottom-left($chamfer-width: $chamfer-width, $chamfer-height: $chamfer-height)
            }
        }
    }
}

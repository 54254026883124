﻿
@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";
@import "../Variables/_z-index.scss";
@import "../Mixins/_typography.scss";

.navigation {
    $portrait-tablet-breakpoint: 1024px;

    z-index: $z-index-navigation;
    @media screen and (min-width: $portrait-tablet-breakpoint) {
        position: sticky;
        top: 64px + 70px;
    }

    .navigation-list {
        margin-inline: $inline-content-spacing;
        margin-block: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        list-style: none;

        .navigation-item {
            flex-basis: 0;
            flex-grow: 1;
            padding-inline: 24px;
            background-color: $cloud-white-100;
        }

        .navigation-link {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $atlantic-blue-100;
            border-top-style: solid;
            border-top-color: $afternoon-25;

            &.current {
                border-top-color: $afternoon-100;
            }
        }

        .additional-navigation-item {
            background-color: $bark-75;

            .navigation-link:not(.current) {
                border-block-style: none;
            }
        }
    }

    
    @media not screen and (min-width: $portrait-tablet-breakpoint) {
        .navigation-list {
            margin-top: -42px;
            flex-direction: column;
            .navigation-item:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-top: solid 1px $atlantic-blue-50;
            }
            .navigation-item {
                border-inline: solid 1px $atlantic-blue-50;
            }
            :nth-last-child(1 of .navigation-item) {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border-bottom: solid 1px $atlantic-blue-50;
            }
            .additional-navigation-item {
                border: solid 1px $atlantic-blue-50;
                border-radius: 8px;
                margin-top: 4px;
                padding-inline: 24px;
                .navigation-link {
                    padding-block: 24px;
                    &.current {
                        padding-top: 24px - 3px;
                    }
                }
            }
            .navigation-link {
                padding-top: 24px - 3px;
                padding-bottom: 24px;
                @include h4;
            }
        }
    }

    
    @media screen and (min-width: $portrait-tablet-breakpoint) {
        height: 0;
        .navigation-list {
            margin-top: -70px;
            height: 140px;

            > :first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-left: solid 1px $atlantic-blue-50;
                padding-inline-start: 56px;
            }
            > * {
                border-block: solid 1px $atlantic-blue-50;
            }
            > :nth-last-child(1 of .navigation-item) {
                padding-inline-end: 56px;
            }
            > :last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-right: solid 1px $atlantic-blue-50;
            }
            > .additional-navigation-item {
                border-left: solid 1px $atlantic-blue-50;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                padding-inline: 48px;
            }

            .navigation-link {
                border-top-width: 6px;
                padding-top: 32px - 6px;
                padding-bottom: 24px;
                @include h6;
            }
        }
    }

    &.sticky {
        .navigation-list {
            @media screen and (min-width: $portrait-tablet-breakpoint) {
                height: 108px;
                > :first-child {
                    border-top-left-radius: 0;
                }
                > :last-child {
                    border-top-right-radius: 0;
                }
            }
        }
    }
}

﻿
.page-body {
    $portrait-tablet-breakpoint: 1024px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (min-width: $portrait-tablet-breakpoint) {
        padding-block: 32px;
        display: grid;
        grid-template-columns: 350px 1fr;
        column-gap: 128px;
        padding-block: 128px;
    }
}

﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_typography.scss";

.row.two-column-form-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    @media not screen and (min-width: 640px) {
        grid-template-columns: 1fr;
    }

    .column {
        display: flex;
        flex-direction: column;
    }
}

.ktc-default-section {
    display: flex;
    flex-direction: column;
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
}

.form-field {
    p {
        &:nth-child(1 of p) {
            margin-top: 0;
        }
        &:nth-last-child(1 of p) {
            margin-bottom: 0;
        }
    }
}

.control-label {
    text-transform: uppercase;
    font-size: 12px;
}

.form-control {
    padding-inline: 16px;
    padding-block: 10px;
    border-radius: 8px;
    border: solid 1px $atlantic-blue-25;
    width: 100%;
    box-sizing: border-box;
    background: none;
    font-family: inherit;
    font-size: inherit;
}

.editing-form-control-nested-control::after {
    content: '';
}

.ktc-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    label {
        flex-grow: 1;
    }

    .form-control {
        width: 32px;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        border: solid 1px $blue-gum-100;
        appearance: none;
        background-color: $blue-gum-50;

        &:checked {
            position: relative;

            &::after {
                content: '\2713';
                font-size: 24px;
                color: $atlantic-blue-100;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.field-validation-error {
    @include p3;
    color: $peony-100;
    margin-top: 8px;
}

.masked-input {
    position: relative;
}

.masked-input .prefix {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.masked-input .form-control {
    text-indent: 16px;
}

.kfs-form-validation-error {
    display: block;
    padding: 20px;
    border-radius: 8px;
    background-color: $peony-25;
    border: 1px solid $peony-125;
    color: $peony-125;
    text-wrap: normal;
    margin-top: 20px;
}
﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_typography.scss";
@import "../Mixins/_chamfer.scss";

.comparison {
    padding-inline: 0;

    hgroup {
        margin-bottom: 64px;
        @media screen and (min-width: 640px) {
            text-align: center;
        }
    }
    h2 {
        @media screen and (min-width: 640px) {
            text-align: center;
        }
    }
    > hgroup, > h2 {
        @media not screen and (min-width: 640px) {
            padding-inline: 32px;
            margin-bottom: 96px;
            @include h2;
        }
    }

    .comparison-cards {
        display: flex;
        flex-direction: column;

        &:has(.back .stamp) {
            margin-top: 64px;
        }
    }

    .comparison-card {
        position: relative;
        padding: 32px;
        padding-top: 96px;

        $medium-chamfer-size: map-get($chamfer-sizes, 'medium');
        $medium-chamfer-width: map-get($medium-chamfer-size, 'chamfer-width');
        $medium-chamfer-height: map-get($medium-chamfer-size, 'chamfer-height');

        $small-chamfer-size: map-get($chamfer-sizes, 'small');
        $small-chamfer-width: map-get($small-chamfer-size, 'chamfer-width');
        $small-chamfer-height: map-get($small-chamfer-size, 'chamfer-height');

        &.back {
            .background {
                @include chamfer-top-right($chamfer-width: $small-chamfer-width, $chamfer-height: $small-chamfer-height);

                @media screen and (min-width: 640px) {
                    @include chamfer-top-right($chamfer-width: $medium-chamfer-width, $chamfer-height: $medium-chamfer-height);
                }
            }
        }
        &.front {
            .background {
                @include chamfer-top-left($chamfer-width: $small-chamfer-width, $chamfer-height: $small-chamfer-height);

                @media screen and (min-width: 640px) {
                    @include chamfer-top-left($chamfer-width: $medium-chamfer-width, $chamfer-height: $medium-chamfer-height);
                }
            }
        }

        @media screen and (min-width: 640px) {
            padding-top: 128px;
        }

        border-radius: 8px;

        > .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &.back {
            > .background {
                height: calc(100% + #{$small-chamfer-height});

                @media screen and (min-width: 640px) {
                    height: calc(100% + #{$medium-chamfer-height});
                }
            }
        }

        > * {
            position: relative;
        }

        .stamp {
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            width: 96px;
            height: 96px;
            aspect-ratio: 1 / 1;
            box-sizing: border-box;
            padding: 12px;
            border-radius: 192px;
            background-color: $cloud-white-100;

            &.left {
                left: 32px;
            }

            &.right {
                right: 32px;
            }

            .ink {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        h1, h1, h3, h4, h5, h6 {
            margin-bottom: 24px;
        }

        .list {
            list-style: none;
            margin-top: 32px;
            margin-bottom: 0;
            padding: 0;

            &.two-columns {
                display: block;

                @media screen and (min-width: 640px) {
                    column-count: 2;
                }
            }

            .list-item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 16px;
                padding-block: 4px;

                .checkmark {
                    width: 32px;
                    height: auto;
                    aspect-ratio: 1 / 1;
                }

                .description {
                    padding-block: 4px;
                }

                p {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .buttons {
            margin-top: 24px;
        }
    }

    @container page-content (min-width: 1024px) {

        hgroup {
            margin-bottom: 96px;
        }

        .comparison-cards {
            flex-direction: row;
            place-content: center;

            &:has(.back .stamp) {
                margin-top: 128px;
            }
        }

        .comparison-card {
            padding-inline: 64px;
            box-sizing: border-box;

            > .background {
                border-radius: 8px;
            }

            &.back {
                margin-bottom: auto;
                flex-basis: 0;
                flex-grow: 2;
                max-width: 480px;
                padding-bottom: 64px;

                > .background {
                    width: 150%;
                    height: 100%;
                }
            }

            &.front {
                margin-top: 160px;
                flex-basis: 0;
                flex-grow: 3;
                max-width: 640px;
                padding-bottom: 48px;
            }

            .stamp {
                width: 192px;
                height: 192px;
                padding: 32px;
            }
        }
    }
}

﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_typography.scss";

.steps {
    h2 {
        margin-bottom: 64px;
    }

    .steps-list {
        list-style: none;
        grid-template-columns: max-content 1fr;
        column-gap: 16px;

        @media screen and (min-width: 640px) {
            column-gap: 32px;
        }

        margin-block: 64px;
        padding: 0;

        &:not([hidden]) {
            display: grid;
        }

        .step {
            display: contents;

            .ordinal {
                display: flex;
                flex-direction: column;
                align-items: center;

                .circle {
                    background-color: $blue-gum-25;
                    border-radius: 50%;
                    aspect-ratio: 1 / 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    @include p1;

                    @media screen and (min-width: 640px) {
                        width: 96px;
                        @include h4;
                    }
                }

                .stem {
                    @media screen and (min-width: 640px) {
                        height: 100%;
                        width: 2px;
                        background-color: $blue-gum-25;
                    }
                }
            }

            .details {
                font-size: 18px;

                @media screen and (min-width: 640px) {
                    padding-top: 24px;
                }

                h3 {
                    margin-bottom: 24px;

                    @media screen and (min-width: 640px) {
                        margin-bottom: 40px;
                    }
                }

                ul {
                    margin-block: 24px;
                    list-style: outside;
                    padding-left: 16px;

                    @media screen and (min-width: 640px) {
                        padding-left: 28px;
                    }

                    li {
                        padding-left: 4px;
                        margin-bottom: 8px;
                    }
                }

                .requirements-list {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    padding: 0;
                    margin-block: 0;

                    li {
                        display: contents;
                    }

                    .requirement {
                        border-radius: 8px;
                        padding-block: 16px;
                        border-color: $afternoon-50;
                        border-style: solid;
                        border-width: 1px;
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-template-areas: 'icon checkbox' 'description .';
                        gap: 8px;
                        padding-inline: 16px;

                        @media screen and (min-width: 640px) {
                            display: flex;
                            flex-direction: row;
                            gap: 24px;
                            align-items: center;
                            padding-inline: 24px;
                        }

                        .icon {
                            height: auto;
                            aspect-ratio: 1 / 1;
                            grid-area: icon;
                            width: 32px;

                            @media screen and (min-width: 640px) {
                                width: 52px;
                            }
                        }

                        .description {
                            flex-basis: 0;
                            flex-grow: 1;
                            font-size: 18px;
                            grid-area: description;
                        }

                        .checkbox {
                            width: 32px;
                            height: auto;
                            aspect-ratio: 1 / 1;
                            border-radius: 4px;
                            border: solid 1px $blue-gum-100;
                            appearance: none;
                            background-color: $blue-gum-50;
                            grid-area: checkbox;

                            &:checked {
                                position: relative;

                                &::after {
                                    content: '\2713';
                                    font-size: 24px;
                                    color: $atlantic-blue-100;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }
                }
            }

            &:not(:nth-last-child(1 of .step)) {
                .details {
                    padding-bottom: 24px;

                    @media screen and (min-width: 640px) {
                        padding-bottom: 48px;
                    }
                }
            }
        }
    }

    .ineligible {
        background-color: $peony-25;
        border: solid 1px $peony-50;
        color: $peony-125;
        padding-inline: 32px;
        padding-block: 16px;
        border-radius: 8px;
        grid-column-start: 2;
    }

    .continue {
        button {
            @include b1;
            padding-inline: 16px;
            height: 52px;
            box-sizing: border-box;
        }
    }

    .finalize {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-block: 40px;
        grid-column-start: 2;
    }
}

﻿
@import "../Variables/_colours.scss";

.shaded-cards {
    $gap: 16px;
    $overscroll: 32px;
    $card-colours: (
        (bark, $bark-75, $bark-50, $bark-25),
        (blue-gum, $blue-gum-75, $blue-gum-50, $blue-gum-25),
        (afternoon, $afternoon-75, $afternoon-50, $afternoon-25),
    );

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-inline: 0;
    width: unquote("min(992px, 100%)");
    margin-inline: auto;

    &:not(.breakout) {
        padding-inline: 0;
    }

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 24px;
        padding-inline: 32px;

        .stamp {
            height: 80px;
            width: auto;
            aspect-ratio: 1/1;
            border-radius: 100%;
            padding: 8px;

            @each $card-colour, $first-card-colour, $second-card-colour, $third-card-colour in $card-colours {
                &.#{$card-colour} {
                    background-color: $third-card-colour;
                }
            }
        }
    }

    .shaded-cards-slider {
        margin: 0;
        padding: 0;

        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) 2*$overscroll - $gap;
        column-gap: $gap;
        scroll-padding-inline-start: $overscroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .shaded-cards-slides {
            list-style: none;
            display: grid;
            grid-template-columns: ($overscroll - $gap);
            grid-auto-flow: column;
            grid-auto-columns: 100%;
            column-gap: $gap;

            margin: 0;
            padding: 0;

            > :not(.overscroll) {
                scroll-snap-align: start;
            }

            .overscroll {
                width: $overscroll - $gap;
            }
        }
    }

    .shaded-card {
        margin: 0;
        padding: 32px;
        border-radius: 8px;
        grid-row-end: span 3;
        display: grid;
        grid-template-rows: subgrid;

        @each $card-colour, $first-card-colour, $second-card-colour, $third-card-colour in $card-colours {
            &.#{$card-colour} {
                &:nth-child(3n + 1 of .shaded-card) {
                    background: $first-card-colour;
                }

                &:nth-child(3n + 2 of .shaded-card) {
                    background: $second-card-colour;
                }

                &:nth-child(3n + 3 of .shaded-card) {
                    background: $third-card-colour;
                }
            }
        }
    }
}

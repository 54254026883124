﻿
@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";

$alert-colours: (
    (soft-peony, $peony-25, $peony-125),
    (grass-green, #b9d6b9, $atlantic-blue-100),
    (sunflower-yellow, #fff75f, $atlantic-blue-100),
    (afternoon-blue-tint, #c1d4eb, $atlantic-blue-100),
    (blue-gum-tint, #d6e3e6, $atlantic-blue-100),
    (vivid-peony, #ff767b, $cloud-white-100),
    (wisteria, #8759eb, $cloud-white-100),
    (rose-red, #ba2f2f, $cloud-white-100),
);

.alert-banner {
    @each $name, $background, $text in $alert-colours {
        &.#{$name} {
            background-color: $background;
            color: $text;
        }
    }
    &:not([hidden]) {
        display: flex;
    }
    padding-block: 12px;
    padding-inline: $inline-content-spacing;
    
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-height: 48px;
    box-sizing: border-box;
    column-gap: 16px;
    row-gap: 4px;
}

.alert-banner-icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
}

.alert-banner-header {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
}

.alert-banner-message {
    flex-grow: 1;
}

.alert-banner-controls {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-left: auto;
    align-items: center;
}

.alert-banner-link, .alert-banner-close {
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.hidden {
    display: none;
}

﻿
.two-columns {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 32px;

        > .column {
            flex-basis: 2*32px; /* account for inline padding on widgets in the column, causing more consistent width for the contents themselves */

            > * {
                flex-grow: 1;
            }
        }

        &.one-third-two-thirds {
            > :nth-child(1) {
                flex-grow: 1;
            }
            > :nth-child(2) {
                flex-grow: 2;
            }
        }
        &.half-half {
            > :nth-child(1) {
                flex-grow: 1;
            }
            > :nth-child(2) {
                flex-grow: 1;
            }
        }
        &.two-thirds-one-third {
            > :nth-child(1) {
                flex-grow: 2;
            }
            > :nth-child(2) {
                flex-grow: 1;
            }
        }
    }
}

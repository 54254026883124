﻿
.sidebar {
    $portrait-tablet-breakpoint: 1024px;

    @media not screen and (min-width: $portrait-tablet-breakpoint) {
        display: contents;
    }
    .content {
        position: sticky;
        top: 96px;
        max-width: 100vw;
        @media not screen and (min-width: $portrait-tablet-breakpoint) {
            display: contents;
       
            .aside {
                display: none;
            }
        }
    }
}

.navigation ~ .page-body .sidebar .content {
    top: 240px;
}

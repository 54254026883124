﻿.text-divider {
    display: flex;
    flex-direction: row;
    align-items: center;

    .rule {
        flex-grow: 1;
        border-bottom: solid 1px $afternoon-25;
        height: 1px;
    }

    .bubble {
        background: $cloud-white-100;
        font-size: 16px;
        font-weight: 500;
        border-radius: 64px;
        border: solid 1px $afternoon-25;
        padding: 16px 24px 16px 24px;
        border-radius: 64px;
    }
}

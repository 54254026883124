﻿
@import "../Variables/_colours.scss";

.background {
    &.vivid-bark {
        background-color: $bark-100;
    }
    &.bark-cloud {
        background-image: linear-gradient(30deg, $cloud-white-100 15%, $bark-50 110%);
    }
    &.soft-bark {
        background-color: $bark-25;
    }
    &.cloud-bark {
        background-image: linear-gradient(30deg, $cloud-white-100 15%, $bark-50 110%);
    }
    &.cloud-afternoon {
        background-image: linear-gradient(100deg, $cloud-white-100 0%, $afternoon-25 90%);
    }
    &.afternoon-cloud {
        background-image: linear-gradient(100deg, $afternoon-25 0%, $cloud-white-100 91%);

        @media screen and (min-width: 640px) {
            background-image: linear-gradient(60deg, $afternoon-25 -6%, $cloud-white-100 93%);
        }
    }
    &.vivid-afternoon {
        background-color: $afternoon-100;
    }
    &.soft-afternoon {
        background-color: $afternoon-25;
    }
    &.vivid-blue-gum {
        background-color: $blue-gum-100;
    }
    &.soft-blue-gum {
        background-color: $blue-gum-25;
    }
    &.atlantic-blue {
        background-color: $atlantic-blue-100;
    }
}

﻿@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";
@import "../Variables/_z-index.scss";
@import "../Mixins/_typography.scss";

.header {
    $landscape-tablet-breakpoint: 1024px;
    $desktop-breakpoint: 1280px;

    width: 100%;
    z-index: min($z-index-primary-header, $z-index-secondary-header);

    @media screen and (min-width: $landscape-tablet-breakpoint) {
        display: contents;
    }

    .mobile-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 16px;
        align-items: center;
        @media screen and (min-width: $landscape-tablet-breakpoint) {
            flex-basis: 0;
            flex-grow: 1;
        }
        @media screen and (min-width: $desktop-breakpoint) {
            flex-basis: 340px;
        }

        a {
            color: inherit;
        }

        .homestart-logo {
            display: block;
            max-width: 100%;
        }
    }

    .corporate-standard-menu {
        background-color: $cloud-white-100;
    }

    .menu-logo-bar {
        z-index: $z-index-primary-header;
        padding-block: 32px;
        display: flex;
        align-items: center;
        column-gap: 48px;
        justify-content: space-between;
        padding-inline: $inline-content-spacing;
        height: 100%;

        @media screen and (min-width: $landscape-tablet-breakpoint) {
            position: sticky;
            top: 0;
        }
    }

    .homestart {
        color: black;
        width: 442px;
        height: 45px;
    }

    .btn-bar {
        box-sizing: border-box;
        padding-block: 20px;
        padding-inline: $inline-content-spacing;
        gap: 8px;
        align-items: center;
        border: 0px 0px 1px 0px;
        display: flex;
        flex-direction: row;
        z-index: $z-index-secondary-header;
    }

    .btn-bar-button {
        width: fit-content;
        box-sizing: border-box;
        padding-inline: 7px;
        padding-block: 3px;
        display: flex;
        align-items: center;
        gap: 0px;
        border-radius: 12px;
        font-size: 12px;
        text-decoration: none;
        height: 24px;

        .btn-img {
            width: 18px;
            height: 18px;
        }

        .label {
            padding-block: 4px;
            padding-inline: 8px;
            font-weight: 500;
        }

        &.search-button {
            position: relative;

            &:has(.search-input:not(:placeholder-shown)) {
                .label {
                    visibility: hidden;
                }
            }

            .search-input {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                padding-inline: 0;
                text-indent: 7px + 16px + 8px; /* padding + icon + gap */
                background: none;
                border: none;
                padding-block: 4px;
                border-radius: 32px;
                font-family: inherit;
                font-weight: 500;
                color: inherit;

                &::placeholder {
                    color: transparent;
                }
            }
        }
    }

    .expandable {
        height: 24px;
    }

    .cp-nav-bar {
        display: flex;
        flex-direction: row;
        row-gap: 10px;
        column-gap: 24px;
        @media screen and (min-width: $desktop-breakpoint) {
            column-gap: 32px;
        }
        @media screen and (min-width: 1408px + 2*32px) {
            column-gap: 48px;
        }

        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .cp-nav-bar a {
        text-decoration: none;
        @include p1;

        @media screen and (min-width: $desktop-breakpoint) {
            @include b1;
        }

        &.lnk-style-solid {
            color: $bark-100;
            background-color: $atlantic-blue-100;
            transition: 0.2s color, 0.2s background-color;

            &:hover {
                color: $atlantic-blue-100;
                background-color: $peony-100;
            }

            border-radius: 100px;
            border: none;
            height: 52px;
            display: flex;
            align-items: center;
            padding-block: 0;
            padding-inline: 24px;
            cursor: pointer;
        }
    }

    .toggle-menu-button {
        background: none;
        border: none;
        cursor: pointer;

        svg {
            width: 52px;
            height: 52px;

            @media screen and (max-width: 560px) {
                width: 40px;
                height: 40px;
            }

            @media screen and (max-width: 420px) {
                width: 32px;
                height: 32px;
            }
        }
    }

    @media not screen and (min-width: $landscape-tablet-breakpoint) {
        position: sticky;
        top: 0;
        left: 0;
        gap: 24px;

        .menu-logo-bar {
            padding-block: 24px;
            flex-direction: column;
            align-items: stretch;
        }

        &.expanded {
            background-color: $afternoon-100;
            color: $cloud-white-100;
            height: 100%;

            .toggle-menu-button {
                .open {
                    display: none;
                }

                .close {
                    display: block;
                }
            }

            .cp-nav-bar {
                gap: 16px;

                .lnk-style-none {
                    padding-bottom: 12px;
                    display: block;
                }
            }
        }

        &.collapsed {
            background-color: $cloud-white-100;
            color: $atlantic-blue-100;

            .cp-nav-bar {
                display: none;
            }

            .toggle-menu-button {
                .open {
                    display: block;
                }

                .close {
                    display: none;
                }
            }

            .btn-bar {
                display: none;
            }
        }

        &.expanded {
            height: 100vh;
            height: 100dvh;
            /* Supporting mobile browsers will adjust the dvh measurement
             * to exclude the portion of the screen obscured by the URL bar when it is visible
             * and include it otherwise.
             * This will cause the menu to scale to the height of the visible area of the screen.
             */
        }

        .cp-nav-bar {
            flex-direction: column;
            align-items: stretch;

            .lnk-style-solid {
                justify-content: space-between;

                .decoration {
                    margin-right: -10px;
                }
            }
        }

        .btn-bar {
            flex-wrap: wrap;
            row-gap: 16px;
        }

        .btn-bar-button {
            background-color: $cloud-white-100;
            border: none;
            color: $atlantic-blue-100;
            line-height: 100%;
            transition: 0.3s background-color;

            &:hover {
                background-color: $bark-100;
            }

            &.search-button {
                width: 100%;
                height: fit-content;
                border-radius: 24px;
                font-size: 26px;
                line-height: 34px;
                font-weight: 500;
                letter-spacing: -1px;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding-inline: 14px;
                background: none;
                border: 1px solid $cloud-white-100;
                color: $cloud-white-100;

                @media not screen and (min-width: $landscape-tablet-breakpoint) {
                    margin-bottom: 16px;
                }

                .label {
                    color: $bark-100;
                }

                .search-input {
                    font-size: inherit;
                    letter-spacing: inherit;
                    text-indent: 14px + 8px; /* button-padding + label-padding */
                    color: $bark-100;
                }

                .search-submit {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: auto;
                    aspect-ratio: 1.15 / 1;
                    font-size: 0;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            &:not(.search-button) {
                text-transform: uppercase;
            }
        }
    }

    @media not screen and (min-width: $landscape-tablet-breakpoint) {
        display: flex;
        flex-direction: column-reverse;
    }

    &.sticky {
        .cp-nav-bar a {
            @media screen and (min-width: $landscape-tablet-breakpoint) {
                @include b2;
            }

            &.lnk-style-solid {
                height: 36px;
                padding-inline: 16px;
            }
        }

        @media screen and (min-width: $landscape-tablet-breakpoint) {
            .menu-logo-bar {
                padding-block: 14px;
                height: 64px;
                box-sizing: border-box;
                margin-bottom: 52px; // expanded-height - collapsed height, prevents content shifting when transitioning state
                .homestart-logo {
                    height: 36px;
                    width: auto;
                }
            }

            .lnk-style-none {
                display: none;
            }
        }
    }

    @media screen and (min-width: $landscape-tablet-breakpoint) {
        .menu-logo-bar {
            background-color: $cloud-white-100;
            color: $atlantic-blue-100;
        }

        .toggle-menu-button {
            display: none;
        }

        .cp-nav-bar {
            .lnk-style-none {
                color: $atlantic-blue-100;

                &:hover {
                    text-decoration: underline;
                }
            }

            .lnk-style-solid {
                .decoration {
                    display: none;
                }
            }
        }

        .btn-bar {
            background: linear-gradient(360deg, #FAF9F5 0%, #FFFFFF 100%);
            height: 48px;
            justify-content: flex-end;
        }

        .btn-bar-button {
            background: $cloud-white-100;
            color: $atlantic-blue-100;
            border: 1px solid $bark-100;
            text-transform: uppercase;

            &:hover, &:focus-within, &:active {
                background: $bark-100;
            }

            &.search-button {
                transition: padding-right 0.3s;

                &:hover, &:focus-within {
                    padding-right: 100px;
                }
            }
        }

        .search-button {
            .search-submit {
                display: none;
            }
        }
    }

    .language-select {
        width: 250px;
    }

    .select {
        position: relative;

        .label {
            flex-grow: 1;
        }

        .btn-bar-button {
            width: 100%;
        }

        button {
            width: 100%;
            text-align: left;
        }

        .chevron {
            transition: transform 0.3s;
            transform: none;
            height: 80%;
        }


        &.expanded {
            @media not screen and (min-width: $landscape-tablet-breakpoint) {
                position: relative;
                top: 24px;
                transform: translateY(-100%);
            }

            .btn-bar-button {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: none;
                background-color: $cloud-white-100;

                @media not screen and (min-width: $landscape-tablet-breakpoint) {
                    color: $atlantic-blue-100;
                }
            }

            .chevron {
                transform: rotate(180deg);
            }
        }

        .drop-down {
            list-style: none;
            margin: 0;
            padding-inline: 0;
            padding-block: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            position: relative;
            z-index: $z-index-secondary-header;
            width: 100%;
            background-color: $cloud-white-100;
            max-height: 400px;
            overflow-y: auto;
            border: solid 1px $bark-100;
            border-top: none;
            box-sizing: border-box;

            li {
                padding-inline: 8px;

                button, a {
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 12px;
                    color: $atlantic-blue-100;
                    text-decoration: none;
                    background: none;
                    border: none;
                    padding-block: 4px;
                    padding-inline: 8px;
                    cursor: pointer;

                    &:hover {
                        background-color: $bark-100;
                    }
                }

                &:not(:last-child) {
                    button, a {
                        border-bottom: solid 1px $bark-100;
                    }
                }
            }
        }
    }

    .notification-badge {
        padding-inline: 4px;
        padding-block: 1px;
        min-width: 8px;
        height: 12px;
        font-weight: 600;
        text-align: center;
        border-radius: 12px;
        line-height: 12px;
        margin-left: 0.2em;

        &:not([hidden]) {
            display: inline-block;
        }
    }

    .btn-bar-button .notification-badge {
        background: $peony-100;
        color: $cloud-white-100;
    }

    .drop-down .notification-badge {
        background: $atlantic-blue-100;
        color: $bark-100;
    }
}

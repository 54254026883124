
@import "../../Variables/_colours.scss";

div#deposit_results {
    display: none;
}

div#borrow_results.calc-results {
    display: none;
}

div#repayments_results.calc-results {
    display: none;
}

.printHeader {
    display: none;
}

.result-header {
    display: flex;
    padding: 32px 40px 32px 40px;
    background-color: $bark-100;
    border-radius: 8px;
    flex-direction: column;
    gap: 8px;
    min-height: 152px;
    margin-bottom: 16px;
    box-sizing: border-box;

    .result-label {
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
    }

    .result-amount {
        font-size: 40px;
        font-weight: 500;
        line-height: 62px;
    }

    @media screen and (max-width: 640px) {
        padding: 24px;
        height: auto;

        .result-label {
            font-size: 20px;
            font-weight: 500;
        }

        .result-amount {
            font-size: 26px;
            line-height: 26px;
        }
    }
}



/* Container for tab headers and content */
.result-tab-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    /* Tab headers container */
    .tab-headers {
        display: flex;
        gap: 8px;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        /* Individual tab header */
        .tab-header {
            padding: 24px 24px 24px 16px;
            flex: 1;
            flex-direction: column;
            gap: 8px;
            border: 1px solid $bark-100;
            border-bottom: 1px solid $bark-100;
            background-color: transparent;
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: $atlantic-blue-100;
            min-height: 108px;
            height: auto;
            border-radius: 8px 8px 0px 0px;
            transition: background-color 0.3s ease;
            box-sizing: border-box;
            /* Active tab header */
            &.is-active {
                border: 1px solid $bark-100;
                border-bottom: none;
                background-color: $cloud-white-100;
                z-index: 2;
                position: relative;

                &:hover {
                    background-color: $cloud-white-100;
                }
            }

            &:hover {
                background-color: $cloud-white-100;
            }

            > span {
                font-size: 18px;
                font-weight: 400;
                display: inline-block;
                width: 100%;
            }

            &.hide {
                display: none !important;
            }

            @media screen and (max-width: 640px) {
                display: none;
            }
        }
    }
    /* Tab content container */
    .mobile-controls {
        display: none;
    }

    hr.breakdown-line {
        @media screen and (max-width: 640px) {
            display: none;
        }
    }

    .tab-content {
        display: none;
        padding: 56px;
        color: $atlantic-blue-100;
        border-left: 1px solid $bark-100;
        border-top: 1px solid $bark-100;
        border-right: 1px solid $bark-100;
        background-color: $bark-100;
        position: relative;
        z-index: 0;
        min-height: 100px;
        margin-top: -1px;
        box-sizing: border-box;
        /* Show is-active content */
        &.is-active {
            display: block;
            background-color: $cloud-white-100;
        }

        .content-title {
            font-size: 40px;
            font-weight: 500;
            margin-bottom: 32px;
            line-height: 45px;
        }

        .content-text {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 52px;
            line-height: 26px;
        }

        .content-details {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .content-detail-item {
                display: flex;
                flex-direction: row;
                gap: 24px;
                align-items: center;

                .svg-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 72px;
                    height: 72px;
                    background-color: $afternoon-25;
                    border-radius: 300px;
                }

                .detail-data {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 9px;

                    .timeframe {
                        span {
                            font-weight: 500;
                        }
                    }

                    .data-amount {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 500;
                    }

                    .data-duration {
                        font-weight: 500;
                    }

                    .data-header {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    }

                    .data-text {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 26px;
                    }
                }
            }
        }

        @media screen and (max-width: 640px) {
            .content-title {
                font-size: 26px;
                line-height: 34px;
                margin-bottom: 28px;
            }
        }
    }

    .breakdown {
        margin-top: 56px;

        .breakdown-toggle {
            display: flex;
            align-items: center;
            margin-top: 24px;
            font-size: 20px;
            font-weight: 500;
        }

        > span {
            cursor: pointer;
        }

        .breakdown-down {
            display: none;
        }

        .breakdown-up {
            display: inline-flex;
        }

        table.fees-and-charges {
            @media screen and (max-width: 640px - 1px) {
                display: flex;
                flex-direction: column;
                tr {
                    display: flex;
                    flex-direction: column;

                    td:nth-child(1) {
                        border-top: solid 2px $atlantic-blue-100;
                        padding-top: 16px;
                    }
                    td:nth-child(2) {
                        padding-bottom: 16px;
                    }
                    &.sub {
                        margin-left: 16px;
                        padding-left: 16px;
                        border-left: solid 2px $afternoon-50;
                        td {
                            padding-left: 16px;
                        }
                    }
                }
            }
            @media screen and (min-width: 640px) {
                display: table;
            }
            font-size: 18px;
            width: 100%;
            margin-top: 32px;

            tr {
                &.bold {
                    @media print, screen and (min-width: 640px) {
                        line-height: 60px;
                    }
                    font-weight: 500;
                }

                &.sub {
                    @media print, screen and (min-width: 640px) {
                        line-height: 44px;
                    }
                    font-weight: 400;

                    span {
                        @media print, screen and (min-width: 640px) {
                            margin-left: 32px;
                        }
                    }
                }

                td {
                    @media print, screen and (min-width: 640px) {
                        border-top: 1px solid $afternoon-25;
                    }
                }

                &.total {
                    margin-top: 16px;
                    
                    @media print, screen and (min-width: 640px) {
                        margin-top: 0;
                    }
                    td {
                        @media print, screen and (min-width: 640px) {
                            border-top: 1px solid $afternoon-75;
                            line-height: 60px;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &.breakdown-hidden {
            .breakdown-up {
                display: none;
            }

            .breakdown-down {
                display: inline-block;
            }

            table.fees-and-charges {
                display: none;
            }
        }
    }

    @media screen and (max-width: 640px) {
        table.fees-and-charges {
            margin-bottom: 26px;
            min-width: 446px;
        }

        .tab-headers {
            display: none;
        }

        .mobile-controls {
            display: flex;
            width: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: 1px solid #E4DDCB;
            border-bottom: none;
            background-color: #fff;
            z-index: 2;
            position: relative;
            box-sizing: border-box;
            flex-direction: column;
            gap: 24px;
            padding: 24px 24px 40px 24px;

            .controls-label {
                font-size: 14px;
                font-weight: 500;
            }

            select.result-dropdown {
                border-radius: 8px;
                padding: 16px;
                font-family: inherit;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                border: none;
                outline: none;
                border-right: 8px solid transparent;
                background-color: $bark-100;

                &:focus-visible {
                    outline: none;
                }

                option {
                    background-color: $cloud-white-100;
                }
            }
        }

        .tab-content {
            padding: 0 24px 24px 24px;
        }

        .tab-content .content-details .content-detail-item .svg-icon {
            min-width: 58px;
            min-height: 58px;
            width: 58px;
            height: 58px;
        }
    }
}

.cta-bar {
    padding: 0px 56px 56px 56px;
    border-radius: 0 0 8px 8px;
    background-color: $cloud-white-100;
    border-left: 1px solid $bark-100;
    border-right: 1px solid $bark-100;
    border-bottom: 1px solid $bark-100;
    border-top: none;
    box-sizing: border-box;

    .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        row-gap: 16px;

        .button-group {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        a.calc-print-button {
            color: $atlantic-blue-100;
            cursor: pointer;
            text-decoration: underline;

            &:visited {
                color: $atlantic-blue-100;
            }
        }
    }
}

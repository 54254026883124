﻿
@import "../Variables/_colours.scss";

.text {
    &.atlantic-blue {
        color: $atlantic-blue-100;
    }
    &.soft-afternoon {
        color: $afternoon-25;
    }
    &.cloud-white {
        color: $cloud-white-100;
    }
    &.bark {
        color: $bark-100;
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}

﻿
@import "../Variables/_colours.scss";

$button-colours: (
    (bark, $bark-100, $atlantic-blue-100),
    (blue-gum, $blue-gum-100, $atlantic-blue-100),
    (atlantic-blue, $atlantic-blue-100, $bark-100),
    (afternoon, $afternoon-100, $atlantic-blue-100),
    (peony, $peony-100, $atlantic-blue-100),
    (cloud-white, $cloud-white-100, $atlantic-blue-100),
);

.button {
    padding-block: 7px;
    padding-inline: 16px;
    border-radius: 32px;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s color, 0.2s background-color, 0.2s border-color;
    box-sizing: content-box;

    &:not([hidden]) {
        display: inline-flex;
    }

    &.solid {
        padding-block: 6px;
        padding-inline: 8px;
        border: none;
    }

    &.hollow {
        padding-block: 5px;
        padding-inline: 7px;
        border-style: solid;
        border-width: 1px;
        background: none;
    }

    @each $name, $background-color, $font-color in $button-colours {
        &.solid.#{$name} {
            background-color: $background-color;
            color: $font-color;
        }

        &.hollow.#{$name} {
            border-color: $background-color;
            color: $background-color;
        }
    }
    /* .button is included again to increase the specificity of this selector such that it can override the colour and style selectors above. */
    &.button:hover {
        background-color: $peony-100;
        border-color: $peony-100;
        color: $atlantic-blue-100;
    }

    .label {
        padding-inline: 8px;
    }

    .decoration, .spinner {
        height: 24px;
        width: 24px;
    }
}

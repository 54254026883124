﻿
.logos {
    display: flex;
    flex-direction: column;
    gap: 16px;

    header {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        h2 {
            flex-grow: 1;
        }
    }

    .logos-grid {
        $grid-gap: 16px;
        $maximum-columns: 4;
        $minimum-column-width: 220px;

        $maximum-gaps: $maximum-columns - 1;
        $maximum-total-gap: $maximum-gaps * $grid-gap;
        $minimum-column-width: "min(#{$minimum-column-width}, 100%)";
        $maximum-column-width: 1fr;
        $column-width-with-maximum-columns: "(100% - #{$maximum-total-gap})/#{$maximum-columns}";
        $column-width: "max(#{$minimum-column-width}, #{$column-width-with-maximum-columns})";

        display: grid;
        grid-template-columns: unquote("repeat(auto-fill, minmax(#{$column-width}, 1fr))");
        gap: $grid-gap;
    }

    .logos-carousel {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 200px;
        gap: 16px;
        overflow-x: scroll;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .logos-list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: contents;

            figure {
                margin: 0;
                padding: 0;
                border: none;
                display: flex;
                flex-direction: column;
                gap: 16px;
                display: grid;
                grid-row-end: span 2;
                grid-template-rows: subgrid;
                align-items: center;
                scroll-snap-align: start;

                .image {
                    width: 100%;
                    height: 200px;
                    object-fit: contain;
                }

                .caption {
                    text-align: center;
                }

                p {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

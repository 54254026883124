﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_typography.scss";

.tiles {
    $tile-colours: (
        (bark, $bark-100, $bark-50),
        (blue-gum, $blue-gum-100, $blue-gum-50),
        (afternoon, $afternoon-100, $afternoon-50),
        (atlantic-blue, $atlantic-blue-100, $atlantic-blue-25),
        (peony, $peony-100, $peony-50),
    );

    hgroup, h2 {
        text-align: center;
    }

    .tiles-grid {
        $tile-gap: 16px;
        $maximum-tiles-per-row: 4;

        $maximum-gaps-per-row: $maximum-tiles-per-row - 1;
        $maximum-total-gap: $maximum-gaps-per-row * $tile-gap;
        $tile-width-with-maximum-tiles-per-row: "(100% - #{$maximum-total-gap})/#{$maximum-tiles-per-row}";
        $minimum-tile-width: 180px;
        $tile-width: "max(#{$minimum-tile-width}, #{$tile-width-with-maximum-tiles-per-row})";

        display: grid;
        grid-template-columns: unquote("repeat(auto-fill, minmax(#{$tile-width}, 1fr))");
        padding: 0;
        gap: $tile-gap;
        list-style: none;
        margin: 0;
        margin-top: 48px;
        @media screen and (min-width: 640px) {
            margin-top: 80px;
        }

        > li {
            @media screen and (min-width: 640px) {
                display: contents;
                > .square {
                    display: none;
                }
            }
            @media not screen and (min-width: 640px) {
                display: grid;
                > * {
                    grid-area: 1 / 1 / 2 / 2;
                }
                > .square {
                    padding-top: 100%;
                }
            }
        }

        .tile {
            margin: 0;
            padding: 16px;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
            @include h6;

            &.hollow {
                border-width: 1px;
                border-style: solid;
                background-color: $cloud-white-100;

                /* .hollow is deliberately listed a second time to increase selector priority above the individual colours */
                &.hollow:hover {
                    border-color: $atlantic-blue-100;
                }
            }

            @each $name, $border, $card in $tile-colours {
                &.#{$name} {
                    &.hollow {
                        border-color: $border;

                        &:hover {
                            background-color: $card;
                        }
                    }
                    &.solid {
                        background-color: $card;
                    }
                }
            }

            &:hover {
                box-shadow: 0px 8px 12px 4px rgba(97, 94, 88, 0.16);
            }

            /* stack the icon and hover icon over each other */
            .feature {
                display: grid;
                width: 64px;
                height: 64px;
                transition: transform 0.3s;
                transform: none;

                > * {
                    grid-area: 1 / 1 / 2 / 2;
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover {
                .feature {
                    transform: scale(110%);
                }
            }

            $hover-transition-duration: 0.3s;
            .icon-hover {
                visibility: hidden;
                opacity: 0%;
                transition: $hover-transition-duration visibility step-end, $hover-transition-duration opacity;
            }
            &:hover .icon-hover {
                visibility: visible;
                opacity: 100%;
                transition: $hover-transition-duration visibility step-start, $hover-transition-duration opacity;
            }
        }

        @media not screen and (min-width: 640px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;

            .tile {
                @include p1;
                flex-direction: column;
                align-items: start;
            }
        }
    }
}

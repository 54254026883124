﻿
.embed {
    .embedded-content {
        border-radius: 8px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        iframe {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

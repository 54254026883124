﻿
@import "../Variables/_colours.scss";

.call-to-action {
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
    max-width: 100vw;

    @media screen and (min-width: 640px) {
        flex-direction: row;
        align-items: center;

        .button {
            margin-left: auto;
        }
    }

    .stamp {
        height: 80px;
        width: 80px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        padding: 16px;
        background-color: $blue-gum-25;
        border-radius: 100%;
    }

    h2 {
        margin-bottom: 0;
    }
}

﻿
@import "../Variables/_colours.scss";

.tags {
	list-style: none;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	margin: 0;
	padding: 0;

	.tag {
		display: block;
		padding-block: 4px;
		padding-inline: 16px;
		border-radius: 32px;
		background-color: $cloud-white-100;
		border-style: none;

		&.blue-gum {
			background-color: $blue-gum-50;
		}
	}

	button, a {
		cursor: pointer;
	}
}

﻿
@import "../Variables/_z-index.scss";

.modal {
    z-index: $z-index-modal;

    .overlay {
        background-color: unquote("rgba(0, 0, 0, 20%)");
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
    }

    .window {
        position: fixed;
        width: unquote("min(640px, calc(100% - 32px))");
        box-sizing: border-box;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background-color: var(--cloud-white-100);
        padding: 32px;
        border-radius: 8px;

        h2 {
            margin-bottom: 32px;
        }
    }
}

﻿
@import "../Variables/_colours.scss";

.quotes {
    $quote-colours: (
        (bark, $bark-100, $bark-50),
        (blue-gum, $blue-gum-100, $blue-gum-50),
        (afternoon, $afternoon-100, $afternoon-50),
        (atlantic-blue, $atlantic-blue-100, $atlantic-blue-25),
        (peony, $peony-100, $peony-50),
    );

    .quotes-grid {
        $grid-gap: 16px;
        $maximum-columns: 4;

        $maximum-gaps: $maximum-columns - 1;
        $maximum-total-gap: $maximum-gaps * $grid-gap;
        $minimum-column-width: "min(180px, 100%)";
        $maximum-column-width: 1fr;
        $column-width-with-maximum-columns: "(100% - #{$maximum-total-gap})/#{$maximum-columns}";
        $column-width: "max(#{$minimum-column-width}, #{$column-width-with-maximum-columns})";

        display: grid;
        grid-template-columns: unquote("repeat(auto-fit, minmax(#{$column-width}, 1fr))");
        margin: 0;
        padding: 0;
        gap: $grid-gap;
        list-style: none;

        .quote {
            border-radius: 8px;
            display: flex;
            padding: 32px;
            flex-direction: column;
            gap: 32px;

            &.hollow {
                border-style: solid;
                border-width: 1px;
            }
            @each $name, $border, $card in $quote-colours {
                &.#{$name} {
                    &.solid {
                        background-color: $card;
                    }
                    &.hollow {
                        border-color: $border;
                    }
                }
            }
            
            blockquote {
                margin: 0;
            }
        }
    }
}

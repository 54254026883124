﻿@import "../Variables/_spacing.scss";
@import "../Mixins/_chamfer.scss";

.articles {
    $gap: 16px;
    $overscroll: 32px;

    padding: 0;
    padding-bottom: 32px;
    &:not(.breakout) {
        padding-inline: calc(#{$inline-content-spacing} - #{$overscroll});
    }

    @media not screen and (min-width: 640px) {
        $mobile-chamfer-size: map-get($chamfer-sizes, 'medium');
        $mobile-chamfer-width: map-get($mobile-chamfer-size, chamfer-width);
        $mobile-chamfer-height: map-get($mobile-chamfer-size, chamfer-height);
        &:has(.chamfer.bottom-left, .chamfer.bottom-right) {
            margin-bottom: $mobile-chamfer-height + 32px;
        }
        &:has(.chamfer.inverse-bottom-left, .chamfer.inverse-bottom-right, .chamfer.top-left, .chamfer.top-right) {
            margin-top: 0;
        }
    }

    header {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        padding: 32px;
        @media screen and (min-width: 640px) {
            display: grid;
            grid-template-columns: [subheading-start] 1fr max-content [subheading-end];
            grid-template-areas: 'heading all-articles';
            column-gap: 32px;
            row-gap: 0px;
            align-items: center;
        }

        hgroup {
            display: contents;
        }
        h2 {
            grid-area: heading;
        }
        .button {
            grid-area: all-articles;
        }
        p {
            grid-column: subheading;
        }
    }

    .article-slider {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(unquote("min(calc(100% - #{2*$overscroll}), 330px)"), 1fr)) 2*$overscroll - $gap;
        column-gap: $gap;
        scroll-padding-inline-start: $overscroll;
        scrollbar-width: none;
        min-height: 650px;

        &::-webkit-scrollbar {
            display: none;
        }

        .article-slides {
            display: grid;
            grid-template-columns: ($overscroll - $gap);
            grid-auto-flow: column;
            grid-auto-columns: 100%;
            column-gap: $gap;
            list-style: none;
            margin: 0;
            padding: 0;

            > :not(.overscroll) {
                scroll-snap-align: start;
            }

            .overscroll {
                width: $overscroll - $gap;
            }
        }
    }

    > .background:first-child, > .background.inverse-none:first-child {
        height: 500px;
        top: unset;
        bottom: 0;
    }
}

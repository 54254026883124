﻿
@import "../Variables/_colours.scss";

.pagination {
    .pagination-navigation {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        @media screen and (min-width: 640px) {
            justify-content: space-between;
        }
        height: fit-content;

        .next, .previous {
            align-items: center;
            display: flex;
            flex-direction: row;
            background: none;
            border-style: none;
        }

        .next, .previous, .page {
            font-weight: 500;
            font-size: 16px;

            &:not([disabled]) {
                cursor: pointer;
                color: $atlantic-blue-100;
            }
            &[disabled] {
                color: $atlantic-blue-50;
            }
        }

        .page-list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: contents;
            @media screen and (min-width: 640px) {
                display: flex;
                flex-direction: row;
                gap: 32px;
            }

            .page {
                font-family: inherit;
                font-size: inherit;
                background: none;
                border-style: none;
                height: 3em;
                width: auto;
                aspect-ratio: 1 / 1;

                &:not([disabled]) {
                    cursor: pointer;
                }

                &.current {
                    color: inherit;
                }
            }

            .ellipsis {
                height: 3em;
                width: auto;
                aspect-ratio: 1 / 1;
                align-content: center;
                text-align: center;
                display: inline-block;
                user-select: none;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}

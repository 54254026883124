﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_mobile-interactive.scss";

.home-loans {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: unquote("min(992px, 100%)");
    margin-inline: auto;

    @media screen and (min-width: 640px) {
        gap: 24px;
    }

    &:not(.breakout) {
        padding-top: 64px;
    }

    > header {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-inline: 16px;

        @media screen and (min-width: 640px) {
            display: grid;
            grid-template-columns: 1fr max-content;
            align-items: flex-end;
        }
    }

    .home-loan-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 16px;
        flex-direction: column;

        @media screen and (min-width: 640px) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
            row-gap: 0;
        }

        .home-loan {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            flex-basis: 0;
            flex-grow: 1;
            @media screen and (min-width: 640px) {
                display: grid;
                grid-row: span 4;
                grid-template-rows: subgrid;
            }

            > header {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 8px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                button {
                    background: none;
                    border: none;
                    text-align: left;
                    cursor: pointer;
                }

                @media screen and (min-width: 640px) {
                    button {
                        display: contents;

                        .decoration {
                            display: none;
                        }
                    }
                }

                h3 {
                    width: 100%;

                    @include mobile-interactive {
                        padding-top: 24px;
                        padding-inline: 24px;
                        width: 100%;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                .tag {
                    padding-inline: 24px;
                    text-transform: uppercase;
                    font-size: 12px;
                }
            }

            .content {
                padding-bottom: 24px;
                @media screen and (min-width: 640px) {
                    display: grid;
                    grid-row: span 3;
                    grid-template-rows: subgrid;
                }

                .summary {
                    padding-inline: 24px;
                    padding-block: 16px;
                    @media screen and (min-width: 640px) {
                        display: grid;
                        grid-template-rows: subgrid;
                        grid-row: span 2;
                    }
                }

                .details {
                    padding-inline: 24px;
                    padding-block: 16px;
                    border-top-style: solid;
                    border-top-width: 1px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;

                    > section > header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 32px;

                        .expand {
                            padding-block: 4px;

                            .decoration {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    &.collapsed {
                        .expand {
                            .collapse-label {
                                display: none;
                            }
                            .collapse-decoration {
                                display: none;
                            }
                        }
                        .key-facts {
                            mask-size: 100% 100%, 100% 128px;
                            mask-repeat: no-repeat;
                            mask-position: center bottom;
                            mask-composite: subtract;
                            mask-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%), linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(0, 0, 0, 0) 100%);
                            overflow: hidden;
                            height: 134px;
                        }
                    }

                    &.expanded {
                        .expand {
                            .expand-label {
                                display: none;
                            }
                            .expand-decoration {
                                display: none;
                            }
                        }
                    }

                    > footer {
                        margin-top: auto;
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                        flex-wrap: wrap;
                    }
                }
            }
        }

        $colours: (
            (bark, (
                (1, $bark-50, $bark-25, $bark-100, $bark-100),
                (2, $bark-75, $bark-50, $bark-100, $bark-100),
                (3, $bark-100, $bark-75, $bark-25, $bark-25))),
            (blue-gum, (
                (1, $blue-gum-25, $cloud-white-100, $blue-gum-50, $blue-gum-100),
                (2, $blue-gum-50, $blue-gum-25, $blue-gum-75, $blue-gum-100),
                (3, $blue-gum-75, $blue-gum-50, $blue-gum-100, $cloud-white-100))),
            (afternoon, (
                (1, $afternoon-25, $cloud-white-100, $afternoon-50, $afternoon-100),
                (2, $afternoon-50, $afternoon-25, $afternoon-50, $afternoon-100),
                (3, $afternoon-75, $afternoon-50, $afternoon-25, $afternoon-25))),
        );

        @each $name, $variations in $colours {
            @each $index, $card, $highlights, $highlights-separator, $key-facts-separator in $variations {
                &.#{$name} {
                    .home-loan:nth-child(3n + #{$index}) {
                        > header, .summary, .details {
                            background-color: $card;
                        }

                        .details {
                            border-top-color: $key-facts-separator;
                        }
                        .highlights {
                            background-color: $highlights;
                            li:not(:last-child) {
                                border-bottom-color: $highlights-separator;
                            }
                        }
                        .key-facts {
                            dt {
                                border-bottom-color: $key-facts-separator;
                            }
                        }
                    }
                }
            }
        }
    }

    .details {
        align-self: start;
    }

    .highlights {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding-inline: 16px;
        padding-block: 0;
        border-radius: 8px;
        align-self: start;

        li {
            padding-block: 16px;

            &:not(:last-child) {
                border-bottom-style: solid;
                border-bottom-width: 1px;
            }
        }
    }

    .key-facts {
        margin-bottom: 16px;

        dt {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            text-transform: uppercase;
            font-size: 12px;
        }

        dd {
            font-size: 14px;
            margin-inline: 0;
            margin-block: 8px 16px;
        }
    }

    @media not screen and (min-width: 640px) {
        .home-loan {
            &.collapsed {
                header {
                    h3 {
                        button {
                            padding-bottom: 24px;
                        }
                    }
                    .tag {
                        display: none;
                    }

                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                .content {
                    display: none;
                }
            }
            &.expanded {
                button {
                    .decoration {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.landing-content > .home-loans:not(:has(header .button)) {
    h2 {
        text-align: center;
    }
}

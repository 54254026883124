﻿
@import "../Variables/_colours.scss";

.rich-text {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 32px;
    }
    hr {
        margin-block: 24px;
        color: $afternoon-50;
        border-top-style: solid;
        border-bottom-style: none;
    }
    ul, ol {
        padding-left: 28px;
        li {
            padding-left: 4px;
        }
    }
    .bubble {
        position: relative;
        border-radius: 64px;
        border: solid 1px $afternoon-50;
        padding-block: 24px;
        padding-inline: 40px;
        background-color: $cloud-white-100;
        margin-inline: auto;
        width: max-content;
        margin-bottom: -32px;
    }
    hr:has(+ .bubble) {
        margin-bottom: 0;
    }
    hr + .bubble {
        transform: translateY(-50%);
        margin-block: 0;
    }
    table {
        border-spacing: 0;

        $table-colours: (
            (table\:bark, $bark-100),
            (table\:blue-gum, $blue-gum-100),
            (table\:afternoon, $afternoon-50),
        );

        th {
            font-weight: 500;
            text-align: left;
            padding-block: 24px;
            padding-right: 16px;
        }
        td {
            padding-block: 8px;
            padding-right: 16px;
        }
        @each $name, $border-colour in $table-colours {
            &.#{$name} {
                th, td {
                    border-top: solid 2px $border-colour;
                }
            }
        }
    }
    > p:last-child {
        margin-bottom: 0;
    }
}

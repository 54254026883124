﻿
@import "../Variables/_colours.scss";

.accordion {
    $accordion-colours: (
        (bark, $bark-50, $bark-100, $bark-25),
        (blue-gum, $blue-gum-50, $blue-gum-100, $blue-gum-25),
        (afternoon, $afternoon-25, $afternoon-50, $blue-gum-25),
        (atlantic-blue, $atlantic-blue-100, $atlantic-blue-100, $blue-gum-25),
    );
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-block: 32px;

    .section {
        border-radius: 8px;
        
        &.hollow {
            border-style: solid;
            border-width: 2px;
            background-color: $cloud-white-100;
        }
        @each $name, $background-color, $border-color, $hover-color in $accordion-colours {
            &.solid.#{$name} {
                background-color: $background-color;
                &:hover {
                    background-color: $border-color;
                }
            }
            &.hollow.#{$name} {
                border-color: $border-color;
                &:hover {
                    background-color: $hover-color;
                }
            }
        }

        .heading {
            margin: 0;
            padding: 0;

            button {
                width: 100%;
                background: none;
                border: none;
                font-size: 40px;
                color: #0d1545;
                align-items: flex-start;
                text-align: left;
                cursor: pointer;
                padding: 24px 24px 24px 24px;
                display: flex;
                flex-direction: column;
                gap: 6px;

                > span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    gap: 16px;
                }

                .label {
                    flex-basis: 0;
                    flex-grow: 1;
                }

                .chevron {
                    transition: transform 0.5s;
                }

                &.expanded .chevron {
                    transform: rotate(180deg);
                }
            }
        }

        .region {
            margin: 0;
            padding: 0;

            .content {
                padding: 0 24px 24px 24px;

                > p:first-child {
                    margin-top: 0;
                }

                > p:last-child {
                    margin-bottom: 0;
                }

                footer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                }
            }

            overflow: hidden;
        }
    }
}

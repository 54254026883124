﻿
@import "../Variables/_colours.scss";

.introduction {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 48px;

    &:not(.breakout) {
        padding-inline: 64px;
    }

    @container page-content (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        gap: 64px;
    }

    .feature {
        &.top {
            align-content: start;
            margin-top: -64px;
        }
        &.center {
            align-content: center;
        }
        &.bottom {
            align-content: end;
            margin-bottom: -64px;
        }
        text-align: center;

        .image {
            width: 448px;
            height: 348px;
            object-fit: cover;
            max-width: 100%;

            @media screen and (min-width: 640px) {
                height: 624px;
                border-radius: 8px;
            }
        }
    }

    .content {
        flex-basis: min-content;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;

        > p {
            @media not screen and (min-width: 640px) {
                font-size: 22px;
                line-height: 32px;
                font-weight: 500;
                letter-spacing: -1px;
            }
            @media screen and (min-width: 640px) {
                font-size: 26px;
                line-height: 32px;
                font-weight: 500;
                letter-spacing: -1px;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 640px) {
            text-align: center;
            padding-block: 64px;
            gap: 64px;
        }
    }

    .facts-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;

        .fact {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media screen and (min-width: 640px) {
                flex-direction: column;
            }
            @media not screen and (min-width: 640px) {
                gap: 16px;

                &:not(:last-child) {
                    border-bottom: solid 1px $afternoon-50;
                    padding-bottom: 16px;
                }
            }

            .fact-icon {
                width: 64px;
                flex-shrink: 0;
                height: auto;
                aspect-ratio: 1 / 1;
                object-position: center;
                object-fit: contain;

                @media screen and (min-width: 640px) {
                    width: 128px;
                }
            }

            p {
                font-size: 22px;
                line-height: 32px;
                font-weight: 500;
                letter-spacing: -1px;
            }
        }

        @media screen and (min-width: 640px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-items: stretch;
        }
    }
}

﻿
@import "../Variables/_colours.scss";

.cards {
    $card-colours: (
        (bark, $bark-100, $bark-50),
        (blue-gum, $blue-gum-100, $blue-gum-50),
        (afternoon, $afternoon-100, $afternoon-50),
        (atlantic-blue, $atlantic-blue-100, $atlantic-blue-25),
        (peony, $peony-100, $peony-50),
    );

    .cards-list {
        display: none;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(unquote("min(325px, 100%)"), 1fr));
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 16px;

        .card {
            margin: 0;
            display: flex;
            flex-direction: column;

            > :first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            > :last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            .image {
                width: 100%;
                height: 262px;
                object-fit: cover;
            }
            .details {
                padding: 24px;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 32px;
                
                &.hollow {
                    border-width: 1px;
                    border-style: solid;
                }
                .content {
                    > :first-child {
                        margin-top: 0;
                    }
                    > :last-child {
                        margin-bottom: 0;
                    }
                }
                .buttons {
                    margin-top: auto;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 8px;
                }
            }
            &.hollow {
                > :first-child {
                    border-top-width: 1px;
                    border-top-style: solid;
                }
                > * {
                    box-sizing: border-box;
                    border-inline-width: 1px;
                    border-inline-style: solid;
                }
                > :last-child {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                }
            }
            @each $name, $border, $card in $card-colours {
                &.#{$name} {
                    &.hollow {
                        > * {
                            border-color: $border;
                        }
                    }
                    &.solid {
                        > .details {
                            background-color: $card;
                        }
                    }
                }
            }
        }
    }
}

﻿
.gallery {
    .gallery-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        list-style: none;
        margin: 0;
        padding: 0;

        .gallery-list-item {
            &:first-child {
                grid-area: span 3 / span 3;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                border-radius: 8px;
            }
        }
    }

    .show-all {
        position: absolute;
        right: 48px;
        bottom: 48px;
    }

    @container page-content (min-width: 720px) {
        .gallery-list {
            grid-template-columns: repeat(4, 1fr);

            .gallery-list-item {
                &:first-child {
                    grid-area: span 2 / span 2;
                }
            }
        }
    }
}

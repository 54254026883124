﻿
@mixin mobile-interactive {
    @media screen and (min-width: 640px) {
        @content;
        button {
            display: contents;
            cursor: inherit;
        }
    }
    @media not screen and (min-width: 640px) {
        button {
            background: none;
            border: none;
            text-align: left;
            padding: 0;
            width: 100%;
            @content;
            cursor: pointer;
        }
    }
}

﻿
$atlantic-blue-100: #0D1545;
$atlantic-blue-75: #4A5074;
$atlantic-blue-50: #868AA2;
$atlantic-blue-25: #C2C4D0;
$afternoon-100: #6395DA;
$afternoon-75: #8AAFE3;
$afternoon-50: #B1CAED;
$afternoon-25: #D8E5F6;
$cloud-white-100: #FFFFFF;
$blue-gum-125: #A6C7CD;
$blue-gum-100: #BBD2D6;
$blue-gum-75: #CCDDE0;
$blue-gum-50: #DDE9EB;
$blue-gum-25: #EEF4F5;
$bark-100: #E4DDCB;
$bark-75: #EBE5D8;
$bark-50: #F1EEE5;
$bark-25: #F8F7F2;
$peony-125: #CC5358;
$peony-100: #FF767B;
$peony-50: #FFB3B6;
$peony-25: #FFF1F2;

html {
    --atlantic-blue-100: #0D1545;
    --atlantic-blue-75: #4A5074;
    --atlantic-blue-50: #868AA2;
    --atlantic-blue-25: #C2C4D0;
    --afternoon-100: #6395DA;
    --afternoon-75: #8AAFE3;
    --afternoon-50: #B1CAED;
    --afternoon-25: #D8E5F6;
    --cloud-white-100: #FFFFFF;
    --blue-gum-125: #A6C7CD;
    --blue-gum-100: #BBD2D6;
    --blue-gum-75: #CCDDE0;
    --blue-gum-50: #DDE9EB;
    --blue-gum-25: #EEF4F5;
    --bark-100: #E4DDCB;
    --bark-75: #EBE5D8;
    --bark-50: #F1EEE5;
    --bark-25: #F8F7F2;
    --peony-125: #CC5358;
    --peony-100: #FF767B;
    --peony-50: #FFB3B6;
    --peony-25: #FFF1F2;
}

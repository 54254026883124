﻿
@import "../Variables/_colours.scss";
@import "../Variables/_timing.scss";
@import "../Variables/_z-index.scss";
@import "../Mixins/_typography.scss";

.page-navigation {
    $portrait-tablet-breakpoint: 1024px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media not screen and (min-width: $portrait-tablet-breakpoint) {
        background-color: $cloud-white-100;
        border: solid 1px $atlantic-blue-50;
        padding-inline: 32px;
        padding-block: 8px;
        margin-inline: 32px;
        margin-top: 16px;
        position: sticky;
        top: 92px;
        z-index: $z-index-navigation;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        &:not(.sticky) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &.expanded {
            padding-bottom: 32px;
            max-height: calc(100vh - 92px - 32px);
            box-sizing: border-box;
            overflow-y: auto;
        }
    }
    .page-navigation-heading {
        button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: none;
            border: none;
            @include p1;
            text-align: left;
            padding: 0;
            color: $atlantic-blue-100;
            cursor: pointer;

            .decoration {
                flex-shrink: 0;
            }
        }

        @media screen and (min-width: $portrait-tablet-breakpoint) {
            display: none;
        }
    }
    &.collapsed {
        .page-navigation-list {
            @media not screen and (min-width: $portrait-tablet-breakpoint) {
                display: none;
            }
        }
    }
    &.expanded {
        .page-navigation-heading {
            .decoration {
                transform: rotate(180deg);
            }
        }
    }
    .page-navigation-list {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        margin-block: 0;

        .page-navigation-item {
            a {
                color: $atlantic-blue-100;
                text-decoration: none;
                padding-block: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                border-bottom: solid 2px $afternoon-75;

                .track {
                    flex-grow: 1;
                    position: relative;
                }
                .decoration {
                    position: absolute;
                    left: 0;
                    transform: translate(0, -50%);
                    transition-property: left, transform;
                    transition-duration: 0.3s;
                    transition-timing-function: $bounce-timing;
                }
                &:hover {
                    .decoration {
                        left: 100%;
                        transform: translate(-100%, -50%);
                    }
                }
            }
        }
    }
}

﻿@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";
@import "../Variables/_timing.scss";

body {
    $mobile-breakpoint: 640px;
    $landscape-tablet-breakpoint: 1280px;

    .footer {
        position: relative;
        border-radius: 8px;
        padding-top: 80px;
        padding-bottom: 28px;
        padding-inline: $inline-content-spacing;
        background: linear-gradient(300.32deg, #E4DDCB -46.76%, #F8F7F2 90.03%);
        min-height: 300px;
        color: $atlantic-blue-100;
        display: flex;
        flex-direction: column;
        gap: 48px;

        @media screen and (min-width: $landscape-tablet-breakpoint) {
            gap: 64px;
        }

        a, a:visited {
            color: $atlantic-blue-100;
        }

        .button {
            padding-block: 4px;
            padding-inline: 8px;
            border-radius: 32px;

            .label {
                padding-block: 6.5px;
                padding-inline: 8px;
                line-height: 1;
            }

            @media screen and (max-width: $landscape-tablet-breakpoint) {
                padding-block: 10px;
                padding-inline: 12px;
            }
        }



        .corner-element {
            display: none;

            @media screen and (min-width: $landscape-tablet-breakpoint) {
                display: block;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            .back-to-top-corner {

                svg {
                    display: block;
                }

                .back-to-top-link {
                    color: $atlantic-blue-100;
                    position: absolute;
                    bottom: 24px;
                    right: 30px;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    gap: 4px;

                    .decoration {
                        position: relative;
                        top: 0;
                        transition-property: top;
                        transition-duration: 0.3s;
                        transition-timing-function: $bounce-timing;
                    }

                    &:hover {
                        .decoration {
                            top: -24px;
                        }
                    }

                    span {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        }

        .menu-title {
            font-size: 12px;
            font-weight: 500;
            padding-inline: 0;
        }

        .menu-item-footer {
            display: flex;
            flex-direction: row;
            gap: 12px;
            border-bottom: 1px solid $bark-100;
            align-items: flex-start;
            padding-block: 16px;
            text-decoration: none;

            .footer-address {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .decoration {
                height: 1.5em;
                min-width: 20px;
            }
        }

        .footer-social-icon {
            display: block;
        }

        .socials {
            .footer-social-icon {
                width: 32px;
                height: 32px;

                @media screen and (max-width: $landscape-tablet-breakpoint) {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        .footer-copy-right {
            font-size: 12px;
            color: $atlantic-blue-100;
            font-weight: 500;
        }

        .divider {
            margin-left: 16px;
            margin-right: 16px;
        }

        .corner-element a, .corner-element a:visited {
            text-decoration: none;
        }

        .sa-brand-logo {
            margin-right: 38px;
        }

        .footer-logo-row {
            justify-content: space-between;
            margin-bottom: 54px;
        }

        .menu-footer-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        @media screen and (min-width: $landscape-tablet-breakpoint) {
            .menu-footer-container {
                min-height: 320px;
                flex-direction: row;
                gap: 64px;
                flex-wrap: wrap;

                > * {
                    flex-basis: 0;
                    flex-grow: 1;
                }
            }
        }

        .footer-menu {
            border-top: solid 2px $atlantic-blue-100;
            display: flex;
            flex-direction: column;

            .menu-title {
                text-transform: uppercase;
                font-size: 16px;
                text-align: left;
                padding-block: 12px;

                &.static {
                    display: none;
                }

                &.toggle {
                    display: block;
                    background: none;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .chevron {
                        transform: none;
                        transition: transform 0.3s;
                    }
                }
            }

            &.expanded {
                .menu-title.toggle {
                    .chevron {
                        transform: rotate(180deg);
                    }
                }
            }

            .content {
                padding-top: 8px;
                padding-bottom: 24px;
            }

            &.collapsed {
                .content {
                    display: none;
                }
            }

            @media screen and (min-width: $landscape-tablet-breakpoint) {
                .menu-title {
                    &.static {
                        display: block;
                    }

                    &.toggle {
                        display: none;
                    }
                }

                &.collapsed {
                    .content {
                        display: block;
                    }
                }
            }
        }

        .footer-links {
            display: flex;
            flex-direction: row;
            flex-flow: wrap;
            justify-content: center;
            column-gap: 48px;
            row-gap: 16px;
            padding-block: 32px;

            @media screen and (min-width: $landscape-tablet-breakpoint) {
                max-width: calc(100% - 200px);
                justify-content: left;
                padding-bottom: 24px;
                max-width: calc(100%);
            }
        }

        .link-item-footer {
            font-size: 14px;
            font-weight: 500;
        }

        .link-item-footer a, .link-item-footer a:visited {
            color: $atlantic-blue-100;
        }

        .footer-copyright {
            @media not screen and (min-width: $landscape-tablet-breakpoint) {
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: center;

                .divider {
                    display: none;
                }
            }

            @media screen and (min-width: $landscape-tablet-breakpoint) {
                display: flex;
                flex-wrap: wrap;
                max-width: calc(100% - 200px);
            }
        }

        .footer-logos {
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex-wrap: wrap;

            > .row {
                align-items: center;

                @media not screen and (min-width: $landscape-tablet-breakpoint) {
                    flex-grow: 1;
                }

                gap: 16px;
                justify-content: center;
            }

            .logos {
                justify-content: center;
                flex-wrap: wrap;

                .footer-social-icon {
                    @media not screen and (min-width: $mobile-breakpoint) {
                        &.hs-footer-logo {
                            width: 100%;
                            height: auto;
                        }

                        &:not(.hs-footer-logo) {
                            display: none;
                        }
                    }

                    &.hs-footer-logo {
                        margin-right: 38px;

                        @media screen and (min-width: $landscape-tablet-breakpoint) {
                            margin-right: 96px;
                        }
                    }
                }
            }
        }

        .newsletter-socials {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: $mobile-breakpoint) {
                padding-top: 32px;
            }
        }

        @media screen and (min-width: $mobile-breakpoint) {
            .footer-logos {
                flex-direction: row;
                justify-content: space-between;
            }

            .newsletter-socials {
                flex-direction: row;
                gap: 16px;
            }
        }

        .socials {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }

        .button {
            @media not screen and (min-width: $landscape-tablet-breakpoint) {
                &.after {
                    display: none;
                }
            }

            @media screen and (min-width: $landscape-tablet-breakpoint) {
                &.before {
                    display: none;
                }
            }
        }
    }
}

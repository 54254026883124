#calculatorPage.page-body {

    @media screen and (max-width: 1160px) {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
}

.calculator-page {
    .print-container {
        display: none
    }

    #borrow, #deposit, #repayments {
        scroll-margin-top: 94px;

        @media screen and (min-width: 640px) {
            scroll-margin-top: 204px;
        }
    }

    .page-content.last {
        margin-top: 30px;
    }
}

.print-only {
    display: none;
}

div#disclaimer {
    max-width: 100vw;
}

.calculator-outer {
    width: 100%;
    max-width: 100vw;

    div.separator {
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;

        &::before {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        &::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        &:not(:empty) {
            &::before {
                margin-right: .25em;
            }

            &::after {
                margin-left: .25em;
            }
        }
    }

    .text-divider {
        margin: 76px 0px;

        @media screen and (min-width: 640px) {
            margin: 24px 0px;
        }
    }

    .calculator {
        border-radius: 8px;
        padding: 48px;
        gap: 40px;
        width: 100%;
        box-sizing: border-box;

        .form-error {
            display: none;
        }

        .form-error.has-error {
            display: block;
        }

        .action-button {

            .button.calculate {
                cursor: pointer;

                .decoration {
                    display: block;
                }

                svg.spinner {
                    display: none;
                }
                /*                &.hollow {
                    background-color: transparent;
                    transition: background-color 1s, border 1s;
                    border: 1px solid var(--atlantic-blue-100);
                    color: var(--atlantic-blue-100);
                    font-weight: 500;
                    border-radius: 32px;
                    font-size: inherit;

                    &:hover {
                        background-color: var(--peony-100);
                        border: 1px solid var(--peony-100);
                    }
                }*/

                &.loading {
                    .noClick {
                        pointer-events: none;
                    }

                    cursor: wait;

                    .decoration {
                        display: none;
                    }

                    svg.spinner {
                        display: block;
                        height: 24px;
                        width: 24px;
                    }
                    /*                    &.hollow {
                        background-color: transparent;
                        transition: background-color 1s, border 1s;
                        border: 1px solid var(--atlantic-blue-100);
                        color: var(--atlantic-blue-50);
                        font-weight: 500;
                        border-radius: 32px;
                        font-size: inherit;

                        &:hover {
                            background-color: var(--atlantic-blue-25);
                            border: 1px solid var(--peony-100);
                        }
                    }*/
                }
            }

            .error {
                display: none;
            }
        }

        .claculator-form {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .form-section {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .form-row {
                    display: grid;
                    width: 100%;
                    gap: 16px;


                    @media screen and (min-width: 1160px) {
                        grid-template-columns: minmax(136px, 1fr) minmax(136px, 1fr);
                    }

                    @media not screen and (min-width: 1160px) {
                        grid-template-columns: minmax(136px, 1fr);
                    }

                    &.full {
                        display: flex;
                    }

                    &.buttons {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .reset {
                            min-width: 146px;
                        }

                        .sub-buttons {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 16px;
                            flex-wrap: wrap;

                            .disclaimer-link, .disclaimer-link:visited {
                                font-size: 18px;
                                color: var(--atlantic-blue-100);
                                font-weight: 400;
                            }
                        }
                    }

                    .calc-error {
                        background-color: var(--peony-25);
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 30px 15px 15px 15px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;

                        h6 {
                            color: var(--peony-125);
                        }

                    }
                }

                .field-label {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;

                    &.number {
                        margin-bottom: 8px;
                    }
                }

                .text-input {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;
                    box-sizing: border-box;

                    &.number {
                        gap: 0;
                    }

                    .header-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                    }

                    .input-field {
                        padding: 10px 16px 10px 16px;
                        border-radius: 8px;
                        font-size: 18px;
                        border: 1.5px solid var(--atlantic-blue-25);

                        &.has-prefix {
                            padding: 10px 16px 10px 40px;
                        }
                    }

                    .suffixes {
                        position: relative;
                        width: 100%;
                    }

                    .suffix {
                        position: absolute;
                        margin-top: 12.5px;
                        margin-left: 16px;
                        font-size: 18px;
                        right: 20px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;

                        button {
                            border: none;
                            border-radius: 3px;
                            background: transparent;
                            padding: 2px;
                            margin: 0;
                            display: flex;
                            height: 20px;
                            align-items: center;
                            cursor: pointer;
                            box-sizing: border-box;

                            &:hover {
                                background-color: $bark-25;

                                svg {
                                    background-color: $bark-25;
                                }
                            }
                        }
                    }

                    .prefix {
                        position: absolute;
                        margin-top: 35.5px;
                        margin-left: 16px;
                        font-size: 18px;

                        &.borrow {
                            margin-top: 8.5px;
                        }
                    }
                }

                .text-input-borrow {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex: 1;
                    display: none;

                    .icon-tooltip-anchor {
                        position: relative;

                        .icon-tooltip {
                            position: absolute;
                            display: none;
                            top: 24px;
                            font-size: .75rem;
                            line-height: 1rem;
                            right: 0px;
                            font-weight: 400;
                            border: 1px solid var(--atlantic-blue-100);
                            border-radius: 8px;
                            padding: 10px;
                            background-color: var(--bark-25);
                            color: var(--atlantic-blue-100);
                            z-index: 10;
                        }

                        &:hover {
                            .icon-tooltip {
                                display: block;
                            }
                        }
                    }



                    .header-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
                    }

                    &.active {
                        display: flex;
                    }

                    .row-block {
                        display: grid;
                        gap: 8px;
                        flex-wrap: wrap;
                        grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));
                        /*                    .text-input.group {
                        max-width: 250px;
                    }*/
                        select.input-field {
                            color: $atlantic-blue-100;
                        }

                        .input-field.frequency-dropdown {
                            height: 42.93px;
                        }
                    }

                    .input-field {
                        padding: 10px 16px 10px 16px;
                        border-radius: 8px;
                        font-size: 18px;
                        border: 1.5px solid var(--atlantic-blue-25);
                        box-sizing: border-box;

                        &.has-prefix {
                            padding: 10px 16px 10px 40px;
                        }
                    }

                    .suffixes {
                        position: relative;
                        width: 100%;
                    }

                    .suffix {
                        position: absolute;
                        margin-top: 12.5px;
                        margin-left: 16px;
                        font-size: 18px;
                        right: 20px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;

                        button {
                            border: none;
                            border-radius: 3px;
                            background: transparent;
                            padding: 2px;
                            margin: 0;
                            display: flex;
                            height: 20px;
                            align-items: center;
                            cursor: pointer;
                            box-sizing: border-box;

                            &:hover {
                                background-color: $bark-25;

                                svg {
                                    background-color: $bark-25;
                                }
                            }
                        }
                    }
                }

                .button-group {
                    gap: 8px;
                    flex: 1;

                    .buttons {
                        gap: 16px;

                        label {
                            display: flex;
                            width: 100%;
                            padding: 10px 12px 10px 12px;
                            gap: 4px;
                            border-radius: 32px;
                            color: var(--atlantic-blue-100);
                            background: var(--cloud-white-100);
                            border: 1px solid var(--atlantic-blue-100);
                            text-align: center;
                            justify-content: center;
                            font-size: 12px;
                            font-weight: 500;
                        }

                        input {
                            display: none;

                            &:checked + label.button, &:checked + label.button:active {
                                background-color: var(--atlantic-blue-100);
                                color: var(--bark-100);
                                border: 1px solid var(--atlantic-blue-100);
                            }
                        }
                    }
                }
            }
        }


        @media screen and (max-width: 640px) {
            padding: 40px 32px;
        }
    }
}

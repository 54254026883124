﻿
@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";
@import "../Mixins/_chamfer.scss";

.hero {
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    .heading {
        padding-inline: 32px;
    }

    .feature {
        position: relative;

        .image {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 30%;
        }
    }

    .breadcrumbs {
        grid-area: breadcrumbs;
        position: relative;
        padding-block: 32px;
    }

    .heading {
        grid-area: heading;
        position: relative;

        .buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;

            .button {
                color: $atlantic-blue-100;
            }
        }
    }

    .feature {
        grid-area: feature;
        position: relative;
    }

    .background {
        grid-area: background;
        position: relative;
    }

    @media not screen and (min-width: 1024px) {
        &.home, &.landing {
            .feature {
                height: 432px;
                margin-inline: 16px;

                .image {
                    $chamfer-size: map-get($chamfer-sizes, medium);
                    @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
                }
            }
        }

        &.content {
            .feature {
                height: 240px;
                margin-inline: 32px;

                .image {
                    $chamfer-size: map-get($chamfer-sizes, small);
                    @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
                }
            }

            .background {
                $chamfer-size: map-get($chamfer-sizes, medium-large);
                @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
            }

            display: grid;
            grid-template-rows: [breadcrumbs-start]
                minmax(125px, max-content)
                [breadrumbs-end feature-start]
                32px
                [background-start]
                240px - 32px
                [feature-end heading-start]
                auto
                [heading-end background-end];
        }

        .heading {
            padding-top: 16px;
            padding-bottom: 32px;
        }

        .breadcrumbs {
            padding-inline: 32px;
        }
    }

    @media screen and (min-width: 1024px) {
        padding-top: 24px;
        display: grid;
        grid-template-columns: [background-start] $inline-content-spacing [breadcrumbs-start heading-start] calc(min(calc(200px + 30%), 640px)) [breadcrumbs-end heading-end feature-start] minmax(0px, 1024px) [feature-end] auto [background-end];
        grid-template-rows:
            [breadcrumbs-start feature-start]
            minmax(125px, max-content)
            [breadcrumbs-end heading-start background-start]
            minmax(350px, max-content)
            [feature-end heading-end background-end];

        &.home {
            grid-template-rows:
                [feature-start heading-start]
                minmax(450px, max-content)
                [feature-end heading-end];
        }
        &.content {
            grid-template-columns: [background-start] $inline-content-spacing [breadcrumbs-start heading-start] 1fr [breadcrumbs-end heading-end feature-start] 1fr [feature-end] $inline-content-spacing [background-end];
        }
    
        & > * {
            position: relative;
        }
        
        .breadcrumbs {
            padding-left: 0;
            padding-right: 128px;
            padding-bottom: 48px;
        }
        .heading {
            padding-top: 16px;
            padding-bottom: 128px;
            padding-left: 0;
            padding-right: 128px;

            h1 {
                padding-bottom: 32px;
            }
        }
        &.home {
            .heading {
                padding-top: 128px;
                padding-right: 64px;
            }
            .feature {
                min-height: 560px;
                
                .image {
                    $chamfer-size: map-get($chamfer-sizes, extra-large);
                    @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
                }
            }
        }
        &.landing {
            .heading {
                padding-right: 64px;
            }
            .feature {
                min-height: 540px;

                .image {
                    $chamfer-size: map-get($chamfer-sizes, large);
                    @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
                }
            }
        }
        &.content {
            .heading {
                padding-top: 88px;
                padding-bottom: 64px;

                p:last-child {
                    margin-bottom: 0;
                }
            }
            .background {
                $chamfer-size: map-get($chamfer-sizes, large);
                @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
            }
            .feature {
                margin-bottom: 64px;

                .image {
                    $chamfer-size: map-get($chamfer-sizes, medium-large);
                    @include chamfer($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
                }
            }
        }
    }
}

﻿@import "../Variables/_colours.scss";

.other-loans {
    padding: 0;

    &:not(.breakout) {
        padding-bottom: 32px;
    }

    > header {
        display: grid;
        grid-template-columns: [subheading-start] 1fr max-content [subheading-end];
        grid-template-areas: 'heading all-articles';
        column-gap: 32px;
        align-items: center;
        padding: 32px;
        padding-top: 64px;

        hgroup {
            display: contents;
        }
        h2 {
            grid-area: heading;
        }
        .button {
            grid-area: all-articles;
        }
        p {
            grid-column: subheading;
        }
    }

    $gap: 16px;
    $overscroll: 32px;
    $slide-width: 360px;

    .other-loans-slider {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(unquote("min(#{$slide-width}, calc(100% - #{2*$overscroll}))"), 1fr)) 2*$overscroll - $gap;
        column-gap: $gap;
        scroll-padding-inline-start: $overscroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .other-loans-slides {
            display: grid;
            grid-template-columns: ($overscroll - $gap);
            grid-auto-flow: column;
            grid-auto-columns: 100%;
            column-gap: $gap;
            list-style: none;
            margin: 0;
            padding: 0;

            > :not(.overscroll) {
                display: contents;

                > * {
                    scroll-snap-align: start;
                }
            }

            .overscroll {
                width: $overscroll - $gap;
            }
        }
    }

    .other-home-loan {
        background: $cloud-white-100;
        border-radius: 8px;
        border-color: $blue-gum-100;
        border-width: 1px;
        border-style: solid;
        padding: 32px;
        grid-row-end: span 4;
        display: grid;
        grid-template-rows: subgrid;
        gap: 24px;

        .highlights {
            list-style: none;
            margin: 0;
            padding-inline: 16px;
            padding-block: 0;
            background-color: $blue-gum-25;
            border-radius: 8px;

            > li {
                padding-block: 16px;

                &:not(:last-child) {
                    border-bottom-color: $blue-gum-50;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                }
            }
        }

        .button {
            margin-top: auto;
        }
    }

    .background {
        height: 300px;
        top: unset;
        bottom: 0;
    }
}

﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_chamfer.scss";
@import "../Variables/_timing.scss";

.category-filter {
    $tablet-breakpoint: 1024px;

    display: flex;
    flex-direction: column;
    padding-block: 24px;
    padding-inline: 32px;
    background-color: $blue-gum-50;
    border-radius: 8px;
    $chamfer-size: map-get($chamfer-sizes, tiny);
    @include chamfer-bottom-right($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));

    @media screen and (min-width: $tablet-breakpoint) {
        $chamfer-size: map-get($chamfer-sizes, small);
        @include chamfer-bottom-right($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
    }

    .category-list {
        list-style: none;
        padding: 0;
    }

    button {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        color: $atlantic-blue-100;
        background: none;
        text-align: left;
        padding-block: 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        border: none;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        font-weight: 500;
    }

    .toggle {
        justify-content: space-between;
        cursor: pointer;

        @media screen and (min-width: $tablet-breakpoint) {
            display: none;
        }
    }
    &.collapsed {
        .category-list {
            @media not screen and (min-width: $tablet-breakpoint) {
                display: none;
            }
        }
    }
    &.expanded {
        .toggle {
            .decoration {
                transform: rotate(180deg);
            }
        }
    }

    .category-list {
        button {
            &.current {
                border-bottom-color: $atlantic-blue-100;

                @media not screen and (min-width: $tablet-breakpoint) {
                    display: none;
                }
            }
            &:not(.current) {
                border-bottom-color: $blue-gum-125;
                cursor: pointer;
                &:hover {
                    .decoration {
                        transform: translateX(20px);
                    }
                }
                .track {
                    flex-grow: 1;
                    position: relative;
                }
                .decoration {
                    position: absolute;
                    left: 0;
                    transform: translate(0, -50%);
                    transition-property: left, transform;
                    transition-duration: 0.3s;
                    transition-timing-function: $bounce-timing;
                }
                &:hover {
                    .decoration {
                        left: 100%;
                        transform: translate(-100%, -50%);
                    }
                }
            }

            .decoration {
                transform: translateX(0);
                transition: 0.2s transform;
            }
        }
    }
}

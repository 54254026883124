﻿
.home-page-body {
    @media screen and (min-width: 640px) {
        display: flex;
        flex-direction: column;
        padding-bottom: 128px;
        row-gap: 128px;
    }
    @media screen and (min-width: 1280px) {
        display: grid;
        grid-template-columns: 350px 1fr;
        column-gap: 64px;
        grid-template-areas:
            'feature feature'
            'sidebar content';
    }

    > .feature {
        grid-area: feature;
        padding-top: 64px;

        @media screen and (min-width: 640px) {
            padding-top: 128px;
        }
    }

    > .sidebar {
        grid-area: sidebar;
    }

    > .content {
        grid-area: content;
    }
}

﻿
@import "../Variables/_colours.scss";
@import "../Variables/_spacing.scss";

.search {
    height: 0;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-inline: $inline-content-spacing;
    display: grid;
    grid-template-columns: [input-start label-start] 1fr [label-end submit-start] max-content [input-end submit-end];

    &:not(:has(.input:placeholder-shown)) {
        .label {
            display: none;
        }
    }
    .label {
        pointer-events: none;
        grid-area: label;
        align-content: center;
        padding-inline: 24px;
        padding-block: 24px;
        @media screen and (min-width: 640px) {
            padding-inline: 56px;
        }
        z-index: 1;
        color: $atlantic-blue-50;
    }
    .input {
        margin: 0;
        height: 100%;
        @media screen and (min-width: 640px) {
            height: 140px;
        }
        background-color: $cloud-white-100;
        border: solid 1px $atlantic-blue-50;
        border-radius: 8px;
        padding-inline: 24px;
        @media screen and (min-width: 640px) {
            padding-inline: 56px;
        }
        grid-area: input;

        &::placeholder {
            color: transparent;
        }
    }
    .submit {
        grid-area: submit;
        padding-block: 12px;
        padding-inline: 24px;
        margin-right: 24px;
        @media screen and (min-width: 640px) {
            margin-right: 48px;
        }
        font-weight: 500;
        font-family: inherit;
    }
}

﻿
@mixin h1 {
	font-size: 58px;
	line-height: 60px;
	font-weight: 500;
	letter-spacing: -2px;
	
	@media screen and (min-width: 640px) {
		font-size: 74px;
		line-height: 78px;
		font-weight: 500;
		letter-spacing: -2px;
	}
}
@mixin h2 {
	font-size: 40px;
	line-height: 48px;
	font-weight: 500;
	letter-spacing: -2px;
	
	@media screen and (min-width: 640px) {
		font-size: 64px;
		line-height: 72px;
		font-weight: 500;
		letter-spacing: -2px;
	}
}
@mixin h3 {
	font-size: 28px;
	line-height: 34px;
	font-weight: 500;
	letter-spacing: -1px;
	
	@media screen and (min-width: 640px) {
		font-size: 56px;
		line-height: 64px;
		font-weight: 500;
		letter-spacing: -2px;
	}
}
@mixin h4 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -1px;
	
	@media screen and (min-width: 640px) {
		font-size: 40px;
		line-height: 48px;
		font-weight: 500;
		letter-spacing: -1.75px;
	}
}
@mixin h5 {
	font-size: 20px;
	line-height: 26px;
	font-weight: 500;
	letter-spacing: 0;
	
	@media screen and (min-width: 640px) {
		font-size: 26px;
		line-height: 32px;
		font-weight: 500;
		letter-spacing: -1px;
	}
}
@mixin h6 {
	font-size: 18px;
	line-height: 22px;
	font-weight: 500;
	letter-spacing: 0;
	
	@media screen and (min-width: 640px) {
		font-size: 22px;
		line-height: 24px;
		font-weight: 400;
		letter-spacing: -1px;
	}
}

@mixin p1 {
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
}
@mixin p2 {
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
}
@mixin p3 {
	font-size: 13px;
	line-height: 18px;
	font-weight: 500;
}

@mixin b1 {
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	letter-spacing: normal;
}

@mixin b2 {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: normal;
}
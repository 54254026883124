﻿@import "../Mixins/_chamfer.scss";

.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.2;
    z-index: 100;
}

.newsletter-subscription {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;



    .newsletter-subscription-form {
        max-width: 1184px;
        position: relative;
        overflow-y: auto;

        .form-modal {
            padding: 80px 80px 45px 80px;
            border-radius: 8px;
            background-color: white;
            max-height: 100vh;
            overflow-y: auto;

            $chamfer-size: map-get($chamfer-sizes, medium);
            @include chamfer-bottom-right($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));

            .form-modal-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                gap: 16px;

                .form-modal-cancel {
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    display: flex;
                    width: 52px;
                    height: 52px;
                    border-radius: 100px;
                    border-width: 2px;
                    border-style: solid;
                    border-color: $atlantic-blue-100;
                    background-color: $cloud-white-100;
                    align-items: center;
                    justify-content: center;
                    transition: 0.2s color, 0.2s background-color, 0.2s border-color;
                    box-sizing: border-box;

                    &:hover {
                        background-color: $peony-100;
                        border-color: $peony-100;
                        color: $atlantic-blue-100;
                    }
                }
            }

            .button.news {
                margin-top: 16px;
            }

            .newsletter-privacy {
                font-size: 12px;
                font-weight: 500;
                padding-right: 80px;
            }
        }
    }

    @media not screen and (min-width: 640px) {
        width: 100vw;
        height: 100vh;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: none;

        .newsletter-subscription-form {
            width: 100vw;
            height: 100vh;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .form-modal {
                border-radius: 0px;
                padding: 15px;
                clip-path: none;
                /*                $chamfer-size: map-get($chamfer-sizes, small);
                @include chamfer-bottom-right($chamfer-width: map-get($chamfer-size, chamfer-width), $chamfer-height: map-get($chamfer-size, chamfer-height));
*/
                .newsletter-privacy {
                    margin-bottom: 35px;
                    padding-right: 0px;
                }
            }
        }
    }
}

﻿
@import "../Mixins/_mobile-interactive.scss";
@import "../Mixins/_chamfer.scss";
@import "../Variables/_colours.scss";
@import "../Variables/_z-index.scss";

.home-loan-cards {
    $mobile-chamfer-size: map-get($chamfer-sizes, 'medium');
    $mobile-chamfer-height: map-get($mobile-chamfer-size, chamfer-height);
    $card-colours: (
        (bark, $bark-100, $bark-100, $bark-50),
        (blue-gum, $blue-gum-100, $blue-gum-75, $blue-gum-50),
        (afternoon, $afternoon-75, $afternoon-25, $afternoon-50),
    );
    width: unquote("min(992px, 100%)");
    box-sizing: border-box;
    margin-inline: auto;

    &:not(.breakout) {
        padding-block: 64px;
    }

    .feature {
        z-index: $z-index-widget-overflow;
        margin-bottom: 32px;

        img {
            object-fit: cover;
            margin-top: -96px;
            @media screen and (min-width: 640px) {
                margin-top: -128px;
            }
            width: 100%;
            max-height: 350px;

            $mobile-image-chamfer: map-get($chamfer-sizes, small);
            $mobile-image-chamfer-width: map-get($mobile-image-chamfer, chamfer-width);
            $mobile-image-chamfer-height: map-get($mobile-image-chamfer, chamfer-height);
            $desktop-image-chamfer: map-get($chamfer-sizes, medium);
            $desktop-image-chamfer-width: map-get($desktop-image-chamfer, chamfer-width);
            $desktop-image-chamfer-height: map-get($desktop-image-chamfer, chamfer-height);
            @include chamfer($chamfer-width: $mobile-image-chamfer-width, $chamfer-height: $mobile-image-chamfer-height);
            @media screen and (min-width: 640px) {
                @include chamfer($chamfer-width: $desktop-image-chamfer-width, $chamfer-height: $desktop-image-chamfer-height);
            }
        }
    }

    > .background:not(.inverse-none) ~ .feature {
        img {
            @media not screen and (min-width: 640px) {
                margin-top: -($mobile-chamfer-height + 64px + 32px);
            }
        }
    } 

    .home-loan-cards-list {
        list-style: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(unquote("min(350px, 100%)"), 1fr));
        gap: 16px;
        margin: 0;

        .home-loan-card {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            margin: 0;
            border-radius: 8px;
            border-style: solid;
            border-width: 1px;

            @each $card-colour, $border-colour, $odd-colour, $even-colour in $card-colours {
                &.#{$card-colour} {
                    border-color: $border-colour;

                    &:nth-child(4n + 1), &:nth-child(4n + 2) {
                        background-color: $odd-colour;
                    }
                    &:nth-child(4n + 3), &:nth-child(4n + 4) {
                        background-color: $even-colour;
                    }
                }
            }

            header {
                h3 {
                    button {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
                .tag {
                    margin-top: 4px;
                }
            }

            &.collapsed {
                @media not screen and (min-width: 640px) {
                    header {
                        h3 {
                            button {
                                padding-bottom: 24px;
                            }
                        }
                        .tag {
                            display: none;
                        }
                    }
                    .summary {
                        display: none;
                    }
                    footer {
                        display: none;
                    }
                }
            }
            &.expanded {
                header {
                    h3 {
                        button {
                            .decoration {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            header {
                h3 {
                    @include mobile-interactive {
                        padding-top: 24px;
                        padding-inline: 24px;
                        display: flex;
                        gap: 16px;
                    }
                }
                .tag {
                    padding-inline: 24px;
                }
            }

            .summary {
                margin-bottom: 8px;
                padding-inline: 24px;
            }

            footer {
                margin-top: auto;
                padding-inline: 24px;
                padding-bottom: 24px;
            }
        }
    }

    @media screen and (min-width: 640px) {
        .feature {
            margin-inline: 32px;
        }
        > header {
            padding-inline: 64px;
        }
        .home-loan-cards-list {
            padding-inline: 64px;
        }
        .home-loan-card {
            header {
                h3 {
                    button {
                        .decoration {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

﻿
@import "../Variables/_colours.scss";

.rows {
    &.breakout {
        @media not screen and (min-width: 640px) {
            padding-inline: 0;
            > hgroup, > h2 {
                padding-inline: 32px;
            }
        }
    }

    .rows-list {
        $grid-gap: 16px;
        $maximum-columns: 2;

        $maximum-gaps: $maximum-columns - 1;
        $maximum-total-gap: $maximum-gaps * $grid-gap;
        $minimum-column-width: "min(600px, 100%)";
        $maximum-column-width: 1fr;
        $column-width-with-maximum-columns: "(100% - #{$maximum-total-gap})/#{$maximum-columns}";
        $column-width: "max(#{$minimum-column-width}, #{$column-width-with-maximum-columns})";

        display: grid;
        grid-template-columns: unquote("repeat(auto-fit, minmax(#{$column-width}, 1fr))");
        list-style: none;
        margin: 0;
        padding: 0;
        column-gap: 16px;
        @media screen and (min-width: 640px) {
            row-gap: 16px;
        }

        .rows-item {
            padding: 24px;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 640px) {
                flex-direction: row;
            }

            $border-colours: (
                (bark, $bark-100),
                (blue-gum, $blue-gum-100),
                (afternoon, $afternoon-100),
            );
            border-top-style: solid;
            border-top-width: 1px;

            @each $name, $colour in $border-colours {
                &.#{$name} {
                    border-top-color: $colour;
                }
            }

            .feature {
                flex-basis: 0;
                flex-grow: 2;
                align-content: center;

                .image {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            .content {
                padding: 24px;
                flex-basis: 0;
                flex-grow: 3;
                align-content: center;

                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

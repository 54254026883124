$print-spacing: 20px !default;
$print-color: #F4F4F4 !default;

@media print {
    html {
        display: block;

        body {
            display: block;
            overflow: visible !important;
            padding: $print-spacing !important;
            height: auto !important;

            > .content-wrapper {
                display: block;

                #main {
                    flex: none;
                }
            }

            .print-container {
                display: none;

                &.selected-for-print {
                    display: block;
                }
            }
        }
    }

/*    .alert-banner {
        display: none!important;
    }
*/
    div[id^="alert_banner"].alert-banner {
        display: none;
    }

    section.hero.landing {
        display: none;
    }

    header#header {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .page-body {
        display: block;
    }
    .page-content {
        padding: 0!important;
    }

    section#how-much-can-i-borrow {
        display: none;
    }

    section#how-much-deposit-do-i-need {
        display: none;
    }

    h2.h4 {
        display: none;
    }

    form#deposit_form, 
    form#repayments_form, 
    form#borrow_form {
        display: none;
    }

    .cta-bar {
        display: none;
    }

    section#what-are-my-repayments {
        display: none;
    }


    nav.navigation {
        display: none;
    }

    .after {
        display: none;
    }

    footer.footer.column {
        display: none;
    }


    .result-header, .tab-header, .breakdown-toggle {
        display: none !important;
    }

    body {
        padding: 50px;
        font-family: Poppins;
    }

    .print-hero svg {
        width: 2.5in !important;
    }

    .print-title, .content-title {
        font-size: 1.5em !important;
        font-weight: 500 !important;
        margin-bottom: 30px !important;
    }

    .print-caption, .content-text {
        font-size: .8em !important;
        font-weight: 400 !important;
    }

    .content-text {
        margin-bottom: 30px !important;
    }

    .print-subtitle {
        margin-top: 30px !important;
        font-size: 1.2em !important;
        font-weight: 500 !important;
        margin-bottom: 15px !important;
    }

    td.label {
        width: 160px !important;
        font-size: .8em !important;
    }

    td.result {
        font-size: .8em !important;
        font-weight: 600 !important;
    }

    #deposit_lowdeposit_loan.hide,
    #deposit_graduate_loan.hide,
    #borrow_lowdeposit_loan.hide,
    #borrow_graduate_loan.hide {
        display: none !important;
    }

    .widget {
        display: none !important;
    }

    .breakdown-toggle {
        display: none !important;
    }

    .print-only {
        display: block;
/*        &.thanks {
            page-break-before: always;
        }
*/        font-size: .8em;
    }

    #disclaimer {
        display: block;
        font-size: .8em;

        p {
            font-size: .8em;
        }
    }

    h3.subtitle {
        font-size: 1em !important;
        font-weight: 600 !important;
    }

    .print-table {
        font-size: .8em !important;
    }

    .result-tab-container {
        page-break-before: always !important;
        display: none;

        &.selected-for-print {
            display: flex;
        }

        .tab-content {
            display: block !important;
            margin-top: 20px;
            padding: 40px 0 0 0 !important;
            border: none !important;
            background-color: #FFF !important;
            page-break-before: avoid !important;
            page-break-inside: avoid !important;
            page-break-after: always !important;

            .content-details {
                gap: 0 !important;

                .content-detail-item {
                    .svg-icon {
                        width: 40px !important;
                        height: 40px !important;
                        background-color: none !important;
                        gap: 0 !important;
                    }

                    .detail-data .timeframe {
                        padding-top: 1em;
                        font-size: 1em;
                    }
                }
            }

            .text-divider {
                page-break-before: always !important;
            }
        }

        .breakdown {
            margin-top: 0 !important;

            table.fees-and-charges {
                display: table !important;
                font-size: .8em !important;
                margin-top: 16px !important;
                max-width: 60%;

                tr {
                    &.sub {
                        line-height: normal !important;
                    }

                    &.bold {
                        line-height: normal !important;

                        td {
                            font-size: 1em;
                        }
                    }

                    td {
                        border-top: none !important;
                    }
                }
            }
        }
    }


    .content-detail-item {
        margin-bottom: 15px !important;
        display: flex !important;
        align-items: center !important;
        gap: 10px !important;
    }

    .data-text {
        font-size: 1.2em !important;
        font-weight: 600 !important;
    }

    tr.bold td {
        font-size: 1.2em !important;
        font-weight: 600 !important;
    }

    tr.sub td span {
        padding-left: 10px !important;
    }

    td:nth-child(2) {
        padding-left: 10px !important;
    }

    .calculator-outer {
        margin: 0 !important;
        padding-top: 0 !important;

        .calculator {
            padding: 0 48px !important;
            background: none !important;
        }
    }

    tr.print-hide {
        display: none !important;
    }
}
﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_chamfer.scss";

.promotion {
    $mobile-chamfer-size: map-get($chamfer-sizes, 'medium');
    $mobile-chamfer-height: map-get($mobile-chamfer-size, chamfer-height);
    position: relative;

    &:not(.breakout) {
        padding-inline: 32px;
        padding-top: 80px;

        @media screen and (min-width: 640px) {
            padding-inline: 48px;
            padding-top: 0;
            padding-bottom: 64px;
        }
    }

    > .background.inverse-none:first-child {
        @media not screen and (min-width: 640px) {
            top: 80px + 88px/2;
            height: calc(100% + #{$mobile-chamfer-height + 32px - (80px + 88px/2)});
        }
        ~ .feature {
            .stamp {
                @media not screen and (min-width: 640px) {
                    height: 88px;
                }
            }
        }
    }

    &:has(> footer):has(.chamfer.bottom-left, .chamfer.bottom-right) {
        @media not screen and (min-width: 640px) {
            margin-bottom: 48px;
        }
        > .background:first-child {
            @media not screen and (min-width: 640px) {
                height: calc(100% + #{$mobile-chamfer-height + 32px + 48px});
            }
        }
        > .background.inverse-none:first-child {
            @media not screen and (min-width: 640px) {
                height: calc(100% + #{-(80px + 88px/2) + 48px});
            }
        }
    }
    @media not screen and (min-width: 640px) {
        &:has(.chamfer.bottom-left, .chamfer.bottom-right) {
            padding-bottom: 0;
        }
    }

    .feature {
        min-height: 64px;
        margin-bottom: 24px;
        @media screen and (min-width: 640px) {
            min-height: 64px;
        }

        .stamp {
            background-color: $afternoon-25;
            height: 128px;
            width: auto;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 100%;
            padding: 16px;
            box-sizing: border-box;
            
            @media screen and (min-width: 640px) {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
            }
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    footer {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.sidebar .page-content, .sidebar .page-content .aside {
    > .promotion:first-child:has(.feature) {
        @media screen and (min-width: 640px) {
            margin-top: 64px;
        }
    }
}

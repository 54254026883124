﻿
@import "../Variables/_z-index.scss";
@import "../Variables/_colours.scss";
@import "../Mixins/_chamfer.scss";

.showcase {
    $mobile-chamfer-size: map-get($chamfer-sizes, 'medium');
    $mobile-chamfer-height: map-get($mobile-chamfer-size, chamfer-height);

    display: flex;
    flex-direction: column;

    & > div {
        max-width: 100vw;
    }

    &:not(.breakout) {
        padding-block: 0;
    }

    &:has(.feature:not(:last-child)) {
        margin-top: 32px;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding-block: 32px;
        padding-top: 64px;

        .stamp {
            display: block;
            height: 128px;
            width: fit-content;
            box-sizing: border-box;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 50%;
            background-color: $afternoon-25;
            padding: 16px;
            margin-bottom: 32px;

            @media screen and (min-width: 640px) {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }

    .feature {
        z-index: $z-index-widget-overflow;
        height: 320px;

        .image {
            margin-block: -64px;
            max-width: 100%;
            height: calc(100% + 32px);
            object-fit: cover;

            $mobile-image-chamfer: map-get($chamfer-sizes, small);
            $mobile-image-chamfer-width: map-get($mobile-image-chamfer, chamfer-width);
            $mobile-image-chamfer-height: map-get($mobile-image-chamfer, chamfer-height);
            $desktop-image-chamfer: map-get($chamfer-sizes, medium);
            $desktop-image-chamfer-width: map-get($desktop-image-chamfer, chamfer-width);
            $desktop-image-chamfer-height: map-get($desktop-image-chamfer, chamfer-height);
            @include chamfer($chamfer-width: $mobile-image-chamfer-width, $chamfer-height: $mobile-image-chamfer-height);

            @media screen and (min-width: 640px) {
                @include chamfer($chamfer-width: $desktop-image-chamfer-width, $chamfer-height: $desktop-image-chamfer-height);
                border-radius: 8px;
            }
        }

        &.top {
            align-content: start;
        }

        &.center {
            align-content: center;

            .image {
                @media screen and (min-width: 640px) {
                    height: calc(100% + #{2*64px})
                }
            }
        }

        &.bottom {
            align-content: end;
        }

        &.columnar {
            .image {
                height: calc(100% + 64px);
            }

            &:not(:last-child) {
                align-content: start;
            }

            &:last-child {
                align-content: end;
            }
        }

        @media not screen and (min-width: 640px) {
            .image {
                height: calc(100% + 32px);
            }

            &:not(:last-child) {
                align-content: start;

                .image {
                    margin-top: -32px;
                }
            }

            &:last-child {
                align-content: end;

                .image {
                    margin-bottom: -32px;
                }
            }
        }
    }

    > .background:not(.chamfer.none) ~ .feature {
        &:last-child {
            @media not screen and (min-width: 640px) {
                margin-bottom: -($mobile-chamfer-height + 32px + 32px);
            }
        }
    }

    @container page-content (min-width: 768px) {
        &:not(.breakout) {
            padding-inline: 32px;

            .content {
                padding-inline: 32px;
            }
        }
    }

    &:has(.feature:not(.columnar)) {
        @media screen and (min-width: 640px) {
            flex-direction: row;
            justify-content: space-between;
            height: fit-content;
            padding-inline: 32px;
            gap: 64px;

            .content {
                padding-inline: 0;
                padding-top: 96px;
                padding-bottom: 64px;
                min-width: 400px;
                flex-basis: 400px;
                flex-grow: 1;
            }

            .feature {
                flex-basis: 600px;
                flex-grow: 1;
                height: 100%;
            }
        }
    }
}

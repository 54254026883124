﻿
@import "../Variables/_colours.scss";
@import "../Mixins/_chamfer.scss";

.article {
    display: flex;
    flex-direction: column;
    height: 100%;

    .heading {
        text-decoration: none;
        width: fit-content;
    }

    .image {
        width: 100%;
        height: auto;
        aspect-ratio: 3 / 2;
        object-fit: cover;

        $chamfer-size: map-get($chamfer-sizes, 'small');
        $chamfer-width: map-get($chamfer-size, 'chamfer-width');
        $chamfer-height: map-get($chamfer-size, 'chamfer-height');
        @include chamfer-top-right($chamfer-width: $chamfer-width, $chamfer-height: $chamfer-height);
    }

    .summary {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px;
        border-top-style: none;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-width: 1px;
        border-color: $blue-gum-100;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        height: 100%;
        background-color: $cloud-white-100;

        p {
            margin-block: 0;
        }
        .button {
            margin-top: auto;
        }
    }
}

﻿
@import "../Variables/_spacing.scss";

.page-section {
    padding-inline: 0;

    @media screen and (min-width: 640px) {
        padding-inline: $inline-content-spacing;
    }
}

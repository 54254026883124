﻿
@import "../Variables/_spacing.scss";

.page-content {
    @media screen and (min-width: 640px) {
        display: flex;
        flex-direction: column;
        gap: 128px;
    }
    @media screen and (min-width: 640px) {
        container-name: page-content;
        container-type: inline-size;
    }

    /* Kentico Xperience 13.0.94 appears to spuriously emit a div with no attributes and containing only whitespace in an editable area, usually but not always at the very end. */
    /* Revisit whether this workaround is required if a hotfix is applied or an alternative explanation for the source of the empty divs is discovered. */
    > div:not([class]):not(:has(*)) {
        display: none;
    }

    &.feature-content {
        padding-bottom: 0;
    }

    > .compact {
        @media not screen and (min-width: 640px) {
            display: contents;
        }
        @media screen and (min-width: 640px) {
            display: flex;
            flex-direction: column;
            gap: 80px;
        }
    }
}

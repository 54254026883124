﻿
.checklist {
    .list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding: 32px;
        position: relative;
        border-radius: 8px;
        width: auto;
        height: fit-content;

        .list-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 16px;

            .checkmark {
                width: 32px;
                height: auto;
                aspect-ratio: 1 / 1;
            }

            .description {
                padding-block: 4px;
                flex-basis: 0;
                flex-grow: 1;
            }

            p {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

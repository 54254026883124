﻿
.breadcrumbs {
    padding: 32px;

    .breadcrumbs-list {
        list-style: none;
        padding-left: 0;
        margin-block: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 4px;
        column-gap: 8px;
        @media screen and (min-width: 640px) {
            column-gap: 16px;
        }

        .breadcrumb {
            display: contents;

            a {
                flex-basis: min-content;
                flex-grow: 1;
	            max-width: max-content;
            }
            .separator {
                height: 1.25em;
                width: auto;
            }
        }
    }
}

﻿#find-a-broker-form {
    height: 0;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-inline: $inline-content-spacing;

    .find-a-broker-search {
        display: grid;
        grid-template-columns: 56px 1fr max-content 56px;
        grid-template-rows: 24px 1fr 24px;
        grid-template-areas:
            '. .     .      .' '. input submit .' '. .     .      .';
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: $cloud-white-100;
        border: solid 1px $atlantic-blue-50;
        border-radius: 8px;
        margin-top: -59px;
        align-items: center;
        position: relative;
        /* clears the ‘X’ from Internet Explorer */
        input[type=search]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        input[type=search]::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
        /* clears the ‘X’ from Chrome */
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            display: none;
        }

        &:has(.input:focus-visible) {
            outline: -webkit-focus-ring-color auto 1px;
        }

        .input {
            grid-area: input;
            border: none;
            line-height: 69px;
            padding: 0;
            outline: none;
            font-size: 22px;
            font-family: inherit;
            width: 100%;

            &::placeholder {
                color: transparent;
            }
        }

        .placeholder {
            color: $atlantic-blue-25;
            font-family: inherit;
            font-size: 22px;
            line-height: 26px;
            grid-area: input;
            pointer-events: none;
        }

        .input:not(:placeholder-shown) ~ .placeholder {
            visibility: hidden;
        }

        .submit {
            grid-area: submit;
            padding-block: 12px;
            padding-inline: 24px;
            font-weight: 500;
            font-family: inherit;
            max-height: 36px;
            align-self: center;
        }

        @media screen and (max-width: 640px) {
            grid-template-columns: 24px 1fr 24px;
            grid-template-rows: 24px 1fr 24px 1fr 24px;
            grid-template-areas:
                '. .      .' '. input  .' '. .      .' '. submit .' '. .      .';

            .input {
                font-size: 18px;
                line-height: 18px;

                &::placeholder {
                    font-family: inherit;
                    font-size: 18px;
                }
            }

            .submit {
                justify-self: center;
            }
        }

        @supports (-webkit-touch-callout: none) {
            -webkit-appearance: none;

            .input {
                background-color: $cloud-white-100;
                vertical-align: middle;
            }
        }
    }
}

#broker_search_results {
    display: flex;
    flex-direction: column;
    padding-inline: calc(max(16px,(100vw - 1408px - 2* 8px) / 2));
    margin-top: 124px;

    @media screen and (min-width: 640px) {
        margin-top: 96px;
    }

    @media screen and (min-width: 420px) {
        padding-inline: calc(max(32px,(100vw - 1408px - 2* 8px) / 2));
    }
}

.container {
    font-family: Arial, Helvetica, sans-serif;
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    background-color: #EEF4F5;
    row-gap: 16px;
    column-gap: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(322px, 1fr));
    padding: 8px;

    @media screen and (min-width: 420px) {
        grid-template-columns: repeat(auto-fit, minmax(398px, 1fr));
        padding: 16px;
    }
}

.element {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #BBD2D6;
    padding: 24px;
    box-sizing: border-box;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

#brokerResultMapView.map-container {
    display: flex;
    padding: 16px;
    gap: 16px;
    border-radius: 8px;
    background-color: var(--blue-gum-25);
    height: 1016px;
    scroll-margin-top: 220px;

    & .broker-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 100%;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */

        & .element.list {
            width: 448px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media screen and (max-height: 1024px) {
        height: 75vh;
    }

    @media screen and (max-width: 1000px) {
        & .broker-list .element.list {
            width: 380px;
        }
    }

    @media screen and (max-width: 868px) {
        & .broker-list {
            display: none;
        }
    }

    @media screen and (max-width:640px) {
        height: 75vh;
        scroll-margin-top: 160px;
    }

    & .broker-g-map {
        flex: 1;
        max-height: 100%;
        background-color: var(--blue-gum-100);
        min-width: 280px;
    }
}

.broke-result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    font-size: 12px;
}

.button-toggle {
    display: flex;
    flex: 0;
    gap: 5px;
}

.contact {
    display: flex;
    gap: 6px;
    align-items: center;
}

.button-toggle button {
    width: 112px;
    height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 32px;
    border: 1px solid #0D1545;
    color: #0D1545;
    background-color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    &.active {
        border: 1px solid #DDE9EB;
        background-color: #DDE9EB;
    }
}

span.company, span.location {
    padding: 4px 8px 4px 8px;
    border-radius: 32px;
    font-size: 12px;
    text-transform: uppercase;
}

span.light {
    color: #0D1545;
    border: 1px solid #DDE9EB;
    background-color: #DDE9EB;
}

span.dark {
    border: 1px solid #0D1545;
    color: #E4DDCB;
    background-color: #0D1545;
}

span.light:empty, span.dark:empty {
    border: none;
    color: transparent;
    background-color: transparent;
}

.element-header {
    display: flex;
    gap: 4px;
    justify-items: center;
    min-height: 24px;
}

span.name {
    font-size: 18px;
    font-weight: 500;
}

.contact > span > a, .contact > span > a:visited {
    text-decoration: none;
    color: inherit;
}
.contact > span {
    flex-basis: 0;
    flex-grow: 1;
}
.contact chrome_annotation {
    border-bottom-style: none !important;
}

.find-a-broker-search {
    .autocomplete-results {
        display: contents;
    }

    .autocomplete-items {
        position: absolute;
        top: 100%;
        overflow-y: auto;
        max-height: 360px;
        background-color: var(--blue-gum-25);
        padding-top: 8px;
        z-index: -1;
        width: max-content;
    }

    .location-item {
        border: 1px solid var(--blue-gum-100);

        &.active {
            border: 2px solid var(--atlantic-blue-100);
        }
    }

    .autocomplete-item {
        padding: 14px 56px;
        background-color: var(--bark-25);
        border: 1px solid var(--atlantic-blue-25);
        color: var(--atlantic-blue-100);
        font-size: 22px;
        border-radius: 8px;
        margin-bottom: 4px;
        cursor: pointer;
        width: 280px;

        @media screen and (max-width: 640px) {
            font-size: 1rem;
            width: 159px;
        }


        &:first-child {
            border: 1px solid var(--atlantic-blue-25);
        }

        &:hover {
            background-color: var(--blue-gum-50);
        }
    }

    .autocomplete-active {
        background-color: var(--blue-gum-50);
    }
}

.page-section.lead-in.find-a-broker {
    padding-top: 30px;
    padding-bottom: 60px;
    min-height: 0px;


    @media screen and (min-width: 640px) {
        padding-block: 128px;
    }

    &.broker-search {

        .text {
            @media screen and (max-width: 640px) {
                padding-bottom: 48px;
            }
        }
    }
}

.no-lead-in {
    min-height: 200px;
    grid-row: lead-in;
}

.separator ~ .separator {
    grid-row: content;
}

﻿@import "../Mixins/_chamfer.scss";
@import "../Variables/_colours.scss";

.widget {
    $mobile-chamfer-size: map-get($chamfer-sizes, 'medium');
    $mobile-chamfer-width: map-get($mobile-chamfer-size, chamfer-width);
    $mobile-chamfer-height: map-get($mobile-chamfer-size, chamfer-height);
    $desktop-chamfer-size: map-get($chamfer-sizes, 'small');
    $desktop-chamfer-width: map-get($desktop-chamfer-size, chamfer-width);
    $desktop-chamfer-height: map-get($desktop-chamfer-size, chamfer-height);

    position: relative;
    scroll-margin-top: 128px;
    max-width: 100vw;

    @media not screen and (min-width: 640px) {
        min-height: 160px + 32px;
        box-sizing: border-box;
        &:has(> .chamfer.bottom-left, > .chamfer.bottom-right) {
            margin-bottom: $mobile-chamfer-height + 32px;
        }
        &:has(> .chamfer.inverse-bottom-left, > .chamfer.inverse-bottom-right, > .chamfer.top-left, > .chamfer.top-right) {
            margin-top: $mobile-chamfer-height;
        }
    }

    @media screen and (min-width: 640px) {
        scroll-margin-top: 96px;
    }

    @media not screen and (min-width: 640px) {
        padding: 32px 48px;
    }

    .breakout {
        @media not screen and (min-width: 640px) {
            margin-inline: 16px;
        }
    }

    &:not(.breakout) {
        @media screen and (min-width: 640px) {
            padding: 32px;
        }
    }

    > .background {
        max-width: 100vw;

        &.bevel {
            @media screen and (min-width: 640px) {
                border-radius: 8px;
            }
        }

        &.chamfer {
            @include chamfer($chamfer-width: $mobile-chamfer-width, $chamfer-height: $mobile-chamfer-height);

            @media screen and (min-width: 640px) {
                @container page-content not (min-width: 768px) {
                    @include chamfer($chamfer-width: $desktop-chamfer-width, $chamfer-height: $desktop-chamfer-height);
                }
            }
        }

        &:first-child {
            position: absolute;
            left: 0;
            width: 100%;
            top: -$mobile-chamfer-height;
            height: calc(100% + #{2*$mobile-chamfer-height + 32px});

            @media screen and (min-width: 640px) {
                top: 0;
                height: 100%;
            }

            @media not screen and (min-width: 640px) {
                &.inverse-bottom-right.bottom-right {
                    clip-path: polygon(
                        0 #{$mobile-chamfer-height},
                        calc(100% - #{$mobile-chamfer-width}) #{$mobile-chamfer-height},
                        100% 0,
                        100% calc(100% - #{$mobile-chamfer-height}),
                        calc(100% - #{$mobile-chamfer-width}) 100%,
                        0 100%
                    );
                }

                &.inverse-bottom-right.none {
                    clip-path: polygon(
                        0 #{$mobile-chamfer-height},
                        calc(100% - #{$mobile-chamfer-width}) #{$mobile-chamfer-height},
                        100% 0, 100% 100%,
                        0 100%
                    );
                }

                &.inverse-none {
                    top: 0;
                    height: calc(100% + #{$mobile-chamfer-height + 32px});
                }
            }
        }
    }

    > * {
        position: relative;
    }

    > h2 {
        margin-bottom: 32px;
    }

    > hgroup {
        h2 {
            margin-bottom: 32px;
        }
    }
}

.navigation ~ .page-body .widget {
    scroll-margin-top: 240px;
}

@media not screen and (min-width: 640px) {
    .page-content:not(.lead-in) .widget:not(:has(> .background:first-child)) {
        background: $cloud-white-100;
    }
}
